import axios from 'axios'
import { endpoints } from '../endpoints'
import { useQuery } from "@tanstack/react-query";
import { useAuth } from 'react-oidc-context';

// Function to get job status by modelId
const getJobStatus = async (modelId: string, tenant: string) => {
    try {
        const token = localStorage.getItem("token");
        const authtype = localStorage.getItem("authtype");
        if (token && authtype) {
            const options = {
                headers: {
                    authtype,
                    Authorization: `Bearer ${token}`,
                    "X-AIS-Tenant-Id": tenant
                }
            };
            const response = await axios.get(`${endpoints.jobStatus}/${modelId}`, options)
            return response.data.data;
            // should return { etlError: true | false}
        }
    } catch (error) {
        console.error('Error fetching job status:', error)
    }
}


const useGetJobstatus = (modelId: string) => {
    const auth = useAuth();

    return useQuery(["jobStatus", modelId], () => {
        return getJobStatus(modelId, auth.user?.profile.tenant_id ?? "")
    }, {
        enabled: !!modelId || modelId.length !== 0
    })
}

export default useGetJobstatus