import React, { useEffect, useState } from 'react'
import { AnalyticsProps } from '../AnalyticsSteps'
import { useResponse } from '../../../Store/Hooks'
import { ConnectorQuery, defaultParams } from './ReverseMLUtils'
import 'react-js-cron/dist/styles.css'
import Checkbox from '../../Checkbox'
import CronJobSelect from '@/Components/CronJobSelect'

const SnowflakeReadParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {

    const { setWarningResponse } = useResponse()
    const [connectorName, setConnectorName] = useState('')
    const [scheduled, setScheduled] = useState(false)
    const [params, setParams] = useState({ ...defaultParams, connectorType: 'snowflake', connectorAction: 'read' })


    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                const _params = _model.config.params.analytics[stepIndex].params
                setConnectorName(_model.config.params.analytics[stepIndex].label)
                setParams({
                    ...params,
                    ..._params,
                })
                if(_params.schedule) {
                    setScheduled(true)
                }
            }
        }
    }, [stepIndex])

    if (!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onScheduledChange = (e: any) => {
        if (!e.target.checked) {
            setParams({ ...params, schedule: null })
        } else {
            setParams({ ...params, schedule: { timezone: '', cron: '* * * * *' } })
        }
        setScheduled(!scheduled)
    }

    const handleTimezoneOnChange = (value: string) => {
        if(!params.schedule) {
            setParams({ ...params, schedule: { timezone: value, cron: '' } })
        } else {
            setParams({ ...params, schedule: { ...params.schedule, timezone: value } })
        }
    }

    const handleCronOnChange = (value: string) => {
        if(!params.schedule) {
            setParams({ ...params, schedule: { timezone: '', cron: value } })
        } else {
            setParams({ ...params, schedule: { ...params.schedule, cron: value } })
        }
    }

    const onSubmit = () => {
        let _params = { ...params }
        if (
            connectorName.length === 0 ||
            _params.account.length === 0 ||
            _params.warehouse.length === 0 ||
            _params.dbName.length === 0 ||
            _params.schema.length === 0 ||
            _params.query.length === 0 ||
            _params.dbUser.length === 0 ||
            _params.password.length === 0
        ) {
            setWarningResponse(
                'Required Fields',
                `Please complete all required fields: ${[
                    'Connector Name',
                    'Account ID',
                    'Warehouse',
                    'Database',
                    'Schema',
                    'Query',
                    'Schedule',
                    'Username',
                    'Password',
                ].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
            )
            return
        }
        onSaveChanges(connectorName, _params)
    }

    return (
        <div className='flex flex-col gap-4'>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                />
            </div>
            <div className='grid grid-cols-12 gap-2'>
                <div className='col-span-full xl:col-span-6'>
                    <label className='px-2 text-[13px] text-ai'>Account ID*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.account ?? ''}
                        name='account'
                    />
                </div>
                <div className='col-span-full xl:col-span-6'>
                    <label className='px-2 text-[13px] text-ai'>Warehouse*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.warehouse ?? ''}
                        name='warehouse'
                    />
                </div>
            </div>
            <div className='grid grid-cols-12 gap-2'>
                <div className='col-span-full xl:col-span-6'>
                    <label className='px-2 text-[13px] text-ai'>Database*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.dbName ?? ''}
                        name='dbName'
                    />
                </div>
                <div className='col-span-full xl:col-span-6'>
                    <label className='px-2 text-[13px] text-ai'>Schema*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.schema ?? ''}
                        name='schema'
                    />
                </div>
            </div>
            <div className='grid grid-cols-12 gap-2'>
                <div className='col-span-full xl:col-span-12'>
                    <label className='px-2 text-[13px] text-ai'>Username*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.dbUser ?? ''}
                        name='dbUser'
                    />
                </div>
            </div>
            <div className='grid grid-cols-6 gap-2'>
                <div className='relative top-1 col-span-full xl:col-span-6'>
                    <label className='px-2 text-[13px] text-ai'>Password*</label>
                    <input
                        className='input w-full'
                        type={'password'}
                        onChange={e => {
                            handleFieldsOnChange(e)
                        }}
                        value={params.password ?? ''}
                        name='password'
                    />
                </div>
            </div>
            <hr className='border-ai-200' />
            <ConnectorQuery _model={_model} connectorName={connectorName} params={params} previewValidations={['account', 'dbUser', 'dbName', 'password', 'schema', 'warehouse', 'query']} queryLanguage='mysql' onQueryChange={(query) => setParams({...params, query: query ?? ''})} />
            {(_model.config.params.analytics?.length === 0 || stepIndex === 0) &&
                <>
                <hr className='border-ai-200' />
                    <div className='flex flex-1 gap-2'>
                        <Checkbox checked={scheduled} onChange={onScheduledChange} />
                        <label className='block px-2 text-ai'>Schedule ETL?</label>
                    </div>
                    {scheduled && (
                        <CronJobSelect timezone={params.schedule?.timezone ?? ''} schedule={params.schedule?.cron ?? ''} onScheduleChange={handleCronOnChange} onTimezoneChange={handleTimezoneOnChange}/>
                    )}
                </>
            }
            <hr className='border-ai-200' />
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button className='btn-secondary border-none bg-transparent text-[#FF0000]' onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default SnowflakeReadParams
