import React from 'react'
import { HarvesterProps } from './HarvesterSteps'
import { useResponse } from '../../Store/Hooks'
import { AddCircleOutline, Remove } from '@mui/icons-material'

const QueryParameterHarvesterParams: React.FC<HarvesterProps> = ({ _model, onSaveChanges, onRemoveClass }) => {
    const { setWarningResponse } = useResponse()
    const [queryKey, setQueryKey] = React.useState('')
    const [advanceOptions, setAdvanceOptions] = React.useState(false)
    const [domElem, setDomElem] = React.useState('')
    const [attribute, setAttribute] = React.useState('')
    const [elements, setElemenets] = React.useState<{ element: string, attribute: string }[]>([])
    const [params, setParams] = React.useState({
        empty: false,
        queryKeys: [] as string[],
        urlLocations: [] as string[],
        attributes: [] as string[],
    })

    React.useEffect(() => {
        if (
            _model &&
            _model.config?.params?.harvestingSteps &&
            _model.config?.params?.harvestingSteps?.length > 0 &&
            _model.config?.params?.harvestingSteps[0].className === 'QueryParameterHarvester'
        ) {
            const _params = { ..._model.config?.params?.harvestingSteps[0].params }
            if(_params.urlLocations?.length > 0 && _params.attributes?.length > 0) {
                setAdvanceOptions(true)
                let _elements = []
                for(const elem in _params.urlLocations) {
                    _elements.push({ element: _params.urlLocations[elem], attribute: _params.attributes[elem] })
                }
                setElemenets(_elements)
            }
            setParams(_params)
        }
    }, [])

    if (!_model) {
        return null
    }

    const onAdvanceOptions = () => {
        setAdvanceOptions(!advanceOptions)
    }

    const onQueryKeyFieldChange = (e: any) => {
        setQueryKey(e.target.value)
    }

    const onAddQueryKey = () => {
        setParams({ ...params, queryKeys: [...params.queryKeys, queryKey] })
        setQueryKey('')
    }

    const onRemoveQueryKey = (index: number) => {
        let keys = [...params.queryKeys]
        keys.splice(index, 1)
        setParams({ ...params, queryKeys: keys })
    }

    const onDomLocationFieldChange = (e: any) => {
        setDomElem(e.target.value)
    }

    const onAttributeFieldChange = (e: any) => {
        setAttribute(e.target.value)
    }

    const onAddDomLocation = () => {
        setElemenets([...elements, { element: domElem, attribute }])
        setDomElem('')
        setAttribute('')
    }

    const onRemoveDomLocation = (index: number) => {
        let keys = [...elements]
        keys.splice(index, 1)
        setElemenets(keys)
    }

    const onSubmit = () => {
        let _params: any = { ...params }
        if (_params.queryKeys.length === 0) {
            setWarningResponse('Required Fields', `Please complete all required fields: Query Keys`)
            return
        }
        if (elements.length === 0) {
            _params.attributes = null
            _params.urlLocations = null
        } else {
            _params.urlLocations = elements.map((e) => e.element)
            _params.attributes = elements.map((e) => e.attribute)
        }
        debugger
        onSaveChanges(_params)
    }

    return (
        <div className='flex flex-col gap-3'>
            <p className='text-ai'>Parameter Names</p>
            <div className='flex flex-col gap-3 rounded-md bg-ai-100 p-3'>
                <div className='flex items-center gap-2'>
                    <div className='flex-1'>
                        <input className='input w-full' type='text' placeholder='id' value={queryKey} onChange={onQueryKeyFieldChange} />
                    </div>
                    <div>
                        <button
                            className='btn-secondary flex items-center border border-ai p-2 text-[14px] font-bold'
                            onClick={onAddQueryKey}
                        >
                            <AddCircleOutline sx={{ fontSize: '16px' }} />
                            <span className='px-1'>Add</span>
                        </button>
                    </div>
                </div>
                <div className='rounded bg-ai-100'>
                    <table aria-label='urls' className='border border-ai-200'>
                        <thead className='border-b border-b-ai-200 bg-white text-left'>
                            <tr>
                                <th className='w-1 px-4 py-2'>
                                    <span className='text-[16px]  text-ai-800'>#</span>
                                </th>
                                <th className='w-full'>
                                    <span className='text-[16px]  text-ai-800'>Query Key</span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className='text-left'>
                            {params.queryKeys.length === 0 && (
                                <tr>
                                    <td colSpan={4}>
                                        <div className='p-6 text-center font-[16px] text-ai-800'>
                                            Add keys found in the URL query parameters.
                                        </div>
                                    </td>
                                </tr>
                            )}

                            {params.queryKeys.map((source: string, index: number) => {
                                return (
                                    <tr key={source + index} className={'bg-ai-50'}>
                                        <td className='p-4 align-top'>
                                            <p className=' text-ai-800'>{index + 1 + '.'}</p>
                                        </td>
                                        <td>
                                            <p className=' w-[25ch] truncate text-ai-800'>{source}</p>
                                        </td>
                                        <td>
                                            <button className='btn-icon' onClick={() => onRemoveQueryKey(index)}>
                                                <Remove color='error' />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='flex items-center gap-2'>
                <p className='text-[16px] text-ai-800'>Advance Options</p>
                <div
                    className={`m-1 flex h-4 w-8 cursor-pointer items-center rounded-full p-1 ${advanceOptions ? 'bg-ai' : 'bg-ai-200'}`}
                    onClick={onAdvanceOptions}
                >
                    <div
                        className={`h-3 w-3 transform rounded-full bg-white shadow-md transition duration-150 ease-in-out ${
                            advanceOptions ? 'translate-x-3' : null
                        }`}
                    ></div>
                </div>
            </div>
            {advanceOptions && (
                <>
                    <p className='text-[14px] text-ai-800'>
                        Use these options if the URL and parameter exists within a DOM elements attribute instead of the borwsers URL bar.
                    </p>
                    <hr className='border-ai-200' />
                    <div className='flex flex-col gap-3'>
                        <p className='text-ai'>Element Locations and Attributes</p>
                        <div className='flex flex-col gap-3 rounded-md bg-ai-100 p-3'>
                            <div className='flex items-center gap-2'>
                                <div className='flex-1'>
                                    <input
                                        className='input w-full'
                                        type='text'
                                        placeholder='#element-id'
                                        value={domElem}
                                        onChange={onDomLocationFieldChange}
                                    />
                                </div>
                                <div className='flex-1'>
                                    <input
                                        className='input w-full'
                                        type='text'
                                        placeholder='data-url'
                                        value={attribute}
                                        onChange={onAttributeFieldChange}
                                    />
                                </div>
                                <div>
                                    <button
                                        className='btn-secondary flex items-center border border-ai p-2 text-[14px] font-bold'
                                        onClick={onAddDomLocation}
                                    >
                                        <AddCircleOutline sx={{ fontSize: '16px' }} />
                                        <span className='px-1'>Add</span>
                                    </button>
                                </div>
                            </div>
                            <div className='rounded bg-ai-100'>
                                <table aria-label='urls' className='border border-ai-200'>
                                    <thead className='border-b border-b-ai-200 bg-white text-left'>
                                        <tr>
                                            <th className='w-1 px-4 py-2'>
                                                <span className='text-[16px]  text-ai-800'>#</span>
                                            </th>
                                            <th className='w-1/2'>
                                                <span className='text-[16px]  text-ai-800'>Selector</span>
                                            </th>
                                            <th className='w-1/2'>
                                                <span className='text-[16px]  text-ai-800'>Attribute</span>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-left'>
                                        {elements.length === 0 && (
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className='p-6 text-center font-[16px] text-ai-800'>
                                                        Use query selectors to identify the DOM elements and the name of the attribute that has the URL value.
                                                    </div>
                                                </td>
                                            </tr>
                                        )}

                                        {elements.map((source: { element: string, attribute: string }, index: number) => {
                                            return (
                                                <tr key={source.element + index} className={'bg-ai-50'}>
                                                    <td className='p-4 align-top'>
                                                        <p className=' text-ai-800'>{index + 1 + '.'}</p>
                                                    </td>
                                                    <td>
                                                        <p className=' w-[25ch] truncate text-ai-800'>{source.element}</p>
                                                    </td>
                                                    <td>
                                                        <p className=' w-[25ch] truncate text-ai-800'>{source.attribute}</p>
                                                    </td>
                                                    <td>
                                                        <button className='btn-icon' onClick={() => onRemoveDomLocation(index)}>
                                                            <Remove color='error' />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <hr className='border-ai-200' />
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button
                    className='btn-secondary border-none bg-transparent text-[#FF0000]'
                    disabled={!_model.config.params.harvestingSteps || _model.config.params.harvestingSteps.length === 0}
                    onClick={onRemoveClass}
                >
                    Remove Class
                </button>
            </div>
        </div>
    )
}

export default QueryParameterHarvesterParams
