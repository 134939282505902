import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetLogs, useGetModel } from '../../features/queries'
import { ColumnDef } from '@tanstack/react-table'
import { DataTable } from '@/Components/tables/DataTable'
import { Button } from '@/Components/ui/button'
import { ArrowDown01, ChevronRight, CopyIcon, Download, X, Plus, Minus } from 'lucide-react'
import { DataTableColumnHeader } from '@/Components/DataTableColumnHeaderProps'
import { ETLJobLog } from '@/features/types'
import { Badge } from '@/Components/ui/badge'
import ModelDash from './ModelDash'
import { Dialog, DialogClose, DialogContent } from '@/Components/ui/dialog'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/Components/ui/tooltip'
import { Label } from '@/Components/ui/label'
import { DialogDataTable } from '@/Components/tables/DialogDataTable'
import CsvDownloader from 'react-csv-downloader'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/Components/ui/collapsible'
import LogoSpinner from '@/Components/LogoSpinner'

const ModelJobsLogs: React.FC<{}> = () => {
    const { id, jobId } = useParams()
    const modelQuery = useGetModel(id)
    const model = modelQuery.data
    const etlLogsQuery = useGetLogs(jobId)
    const logs = etlLogsQuery.data
    const [isHovered, setIsHovered] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [isCollapsableOpen, setIsCollapsableOpen] = useState(true)
    const [activeTooltipKey, setActiveTooltipKey] = useState('')
    const [selectedRow, setSelectedRow] = useState<ETLJobLog | null>(null)
    const [dialogTableColumns, setDialogTableColumns] = useState<ColumnDef<any>[]>([])
    const [dialogTableData, setDialogTableData] = useState<any | any[]>({})

    const getTypeStatus = (status: 'success' | 'error' | 'platform') => {
        let result: { label?: string; className?: string } = {}
        switch (status) {
            case 'success':
                result = { label: 'success', className: 'bg-green-600 hover:bg-green-600' }
                break
            case 'error':
                result = { label: 'error', className: 'bg-red-500 hover:bg-red-500' }
                break
            case 'platform':
                result = { label: 'platform', className: 'bg-ai-500 hover:bg-ai-500' }
                break
            default:
                result = { label: '', className: '' }
                break
        }
        return result
    }

    const dataTableColumns: ColumnDef<ETLJobLog>[] = [
        {
            id: 'index',
            header: ({ column }) => {
                return (
                    <Button
                        className='-mx-2 flex w-10 items-center justify-center px-2'
                        variant='ghost'
                        onClick={() => column.toggleSorting(column.getIsSorted() !== 'asc')}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {isHovered ? <ArrowDown01 className='h-4 w-4' /> : <p>#</p>}
                    </Button>
                )
            },
            accessorKey: 'index',
            cell: cell => {
                return <div className='-ml-1.5 flex w-full items-center justify-center'>{cell.row.index + 1}</div>
            },
        },
        {
            header: ({ column }) => <DataTableColumnHeader column={column} title='Connector Name' />,
            accessorKey: 'connectorName',
            cell: cell => {
                const value = cell.getValue() as string
                return <div className=''>{value}</div>
            },
        },
        {
            header: ({ column }) => <DataTableColumnHeader column={column} title='ID' />,
            accessorKey: 'id',
            cell: cell => {
                const value = cell.getValue() as string
                return <div className='font-bold'>{value}</div>
            },
        },
        {
            header: ({ column }) => <DataTableColumnHeader column={column} title='Created' />,
            accessorKey: 'createdAt',
            cell: cell => {
                const date = new Date(cell.getValue() as Date)
                return date.toLocaleString()
            },
        },
        {
            header: ({ column }) => <DataTableColumnHeader column={column} title='Type' />,
            accessorKey: 'type',
            cell: cell => {
                const statusValue = cell.getValue() as 'success' | 'error' | 'platform'
                const { label, className } = getTypeStatus(statusValue)
                return <Badge className={`rounded-sm ${className}`}>{label}</Badge>
            },
        },
        {
            header: ({ column }) => <DataTableColumnHeader column={column} title='Action' />,
            accessorKey: 'action',
        },
    ]

    const generateDialogTableColumns = (data: string | object | object[]) => {
        let parsedData

        if (typeof data === 'string') {
            try {
                parsedData = JSON.parse(data)
            } catch (error) {
                parsedData = null
            }
        } else {
            parsedData = data
        }

        if (Array.isArray(parsedData) && parsedData.length > 0) {
            const columns = Object.keys(parsedData[0]).map(key => ({
                accessorKey: key,
                header: key,
            }))
            setDialogTableColumns(columns)
            setDialogTableData(parsedData)
        } else if (parsedData && typeof parsedData === 'object') {
            const columns = Object.keys(parsedData).map(key => ({
                accessorKey: key,
                header: key,
            }))
            setDialogTableColumns(columns)
            setDialogTableData([parsedData])
        } else if (parsedData === null && typeof data === 'string') {
            const columns = [
                {
                    accessorKey: 'stringValue',
                    header: 'Value',
                },
            ]
            setDialogTableColumns(columns)
            setDialogTableData([{ stringValue: data }])
        } else {
            setDialogTableColumns([])
            setDialogTableData([])
        }
    }

    const handleRowClick = (row: ETLJobLog) => {
        if (row) {
            setSelectedRow(row)
            setOpenDialog(true)
            generateDialogTableColumns(row.data)
        }
    }

    const handleCopy = (value: string) => {
        if (value) {
            navigator.clipboard.writeText(value)
        }
    }

    const dataColumns = (data: any) => {
        const columns = Object.keys(data)
        return columns.map((col, index) => {
            return {
                id: col,
                displayName: col
            }
        })
    }

    return (
        <ModelDash hideVersionSelect hiddenTabs jobId={jobId}>
            <Dialog open={openDialog} onOpenChange={setOpenDialog}>
                <DialogContent className='w-full border-transparent bg-transparent text-sm shadow-none sm:!max-w-4xl'>
                    <div className='flex items-center rounded-md bg-white shadow'>
                        <div className='flex w-full flex-col gap-4 p-6'>
                            <div className='flex items-center gap-2'>
                                <p className='font-bold text-ai-500'>Log</p>
                                <ChevronRight className='text-gray-500' size={16} />
                                <div className='flex flex-1 gap-2 font-bold text-gray-600'>
                                    {selectedRow?.id}
                                    <TooltipProvider>
                                        <Tooltip open={activeTooltipKey === `id-${selectedRow?.id}`}>
                                            <TooltipTrigger>
                                                <CopyIcon
                                                    className='text-gray-400 hover:cursor-pointer'
                                                    size={16}
                                                    onClick={() => {
                                                        handleCopy(selectedRow?.id || '')
                                                        setActiveTooltipKey(`id-${selectedRow?.id}`)
                                                        setTimeout(() => {
                                                            setActiveTooltipKey('')
                                                        }, 750)
                                                    }}
                                                />
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>Copied</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                                <div className=''>
                                    <DialogClose className='text-gray-400 hover:cursor-pointer' asChild>
                                        <X size={16} />
                                    </DialogClose>
                                </div>
                            </div>
                            <Collapsible defaultOpen={isCollapsableOpen} onOpenChange={() => setIsCollapsableOpen(!isCollapsableOpen)}>
                                <CollapsibleTrigger className='mb-3 flex h-10 w-full items-center gap-2 rounded-md hover:bg-muted/50 '>
                                    {!isCollapsableOpen && <Plus className='relative top-1' size={16} />}
                                    {isCollapsableOpen && <Minus className='relative top-1' size={16} />}
                                    <h1 className='mt-2 text-base font-bold text-gray-600'>Details</h1>
                                </CollapsibleTrigger>
                                <CollapsibleContent>
                                    <div className='grid grid-cols-4 grid-rows-2 gap-y-1 text-xs text-gray-500'>
                                        <div className='col-span-2 flex flex-col gap-3 md:col-span-1'>
                                            <Label>Connector name</Label>
                                            <p className='text-gray-500'>{selectedRow?.connectorName}</p>
                                        </div>
                                        <div className='col-span-2 flex flex-col gap-3 md:col-span-1'>
                                            <Label>Type</Label>
                                            <p className='text-gray-500'>{selectedRow?.type}</p>
                                        </div>
                                        <div className='col-span-2 flex flex-col gap-3 md:col-span-1'>
                                            <Label>Action</Label>
                                            <p className='text-gray-500'>{selectedRow?.action}</p>
                                        </div>
                                        <div className='col-span-2 flex flex-col gap-3 md:col-span-1'>
                                            <Label>Created at</Label>
                                            <p className='text-gray-500'>{new Date(selectedRow?.createdAt as Date).toLocaleString()}</p>
                                        </div>
                                        <div className='col-span-full mt-2 grid gap-2 md:-mt-2'>
                                            <Label>Message</Label>
                                            <div className='w-full rounded-md bg-ai-50 p-4'>
                                                {selectedRow?.message.toLowerCase().includes('success') ? (
                                                    <div className='flex items-center gap-2'>
                                                        <span className=''>
                                                            <svg
                                                                width='24'
                                                                height='24'
                                                                version='1.1'
                                                                viewBox='0 0 1200 1200'
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                className='rounded bg-white/80 fill-green-500 p-0.5 shadow'
                                                            >
                                                                <g fillRule='evenodd'>
                                                                    <path d='m429.38 1011.3c20.699 0 37.5-16.801 37.5-37.5v-430.99c0-20.738-16.801-37.5-37.5-37.5h-188.44c-20.699 0-37.5 16.762-37.5 37.5v430.99c0 20.699 16.801 37.5 37.5 37.5zm-37.5-75h-113.44v-355.99h113.44z' />
                                                                    <path d='m814.95 468.11 8.6992-37.461c13.648-58.648-0.26172-120.34-37.727-167.51-37.461-47.176-94.387-74.664-154.61-74.664h-0.039063c-46.613 0-86.852 32.738-96.336 78.375l-38.25 183.86c-6.6016 31.801-34.613 54.602-67.125 54.602-49.762 0-37.539 58.199-37.539 37.5v301.05c0 92.477 74.961 167.44 167.44 167.44h215.32c99.523 0 184.16-72.602 199.31-170.96 6.8242-44.137 14.438-93.676 21.711-140.7 8.9609-58.164-7.9492-117.38-46.238-162.04-34.312-39.977-82.648-64.762-134.62-69.488zm-347.93 107.21c51.227-13.836 91.875-55.387 103.09-109.35l38.25-183.86c2.25-10.875 11.812-18.637 22.914-18.637h0.039062c37.352 0 72.676 17.062 95.887 46.312 23.25 29.25 31.836 67.5 23.398 103.88 0 0-19.199 82.574-19.199 82.574-2.5859 11.137 0.039062 22.875 7.1641 31.801 7.125 8.9609 17.926 14.211 29.363 14.211h28.539c36.977 0 72.113 16.125 96.148 44.176 24.074 28.086 34.688 65.25 29.062 101.77-7.2383 47.023-14.887 96.602-21.676 140.7-9.5234 61.801-62.699 107.4-125.21 107.4h-215.32c-51.039 0-92.438-41.398-92.438-92.438v-268.54z' />
                                                                    <path d='m675.94 885.98s136.27 34.051 179.14-85.988c6.9766-19.5-3.1875-40.988-22.688-47.926-19.5-6.9766-40.949 3.1875-47.926 22.688-21.039 58.836-89.363 38.738-89.363 38.738-20.023-5.3242-40.539 6.6367-45.863 26.664-5.2891 19.988 6.6758 40.539 26.699 45.824z' />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <div className='flex w-full items-center justify-between'>
                                                            <span>{selectedRow?.message}</span>
                                                            <TooltipProvider>
                                                                <Tooltip open={activeTooltipKey === `message-${selectedRow?.id}`}>
                                                                    <TooltipTrigger>
                                                                        <CopyIcon
                                                                            className='text-gray-400 hover:cursor-pointer'
                                                                            size={16}
                                                                            onClick={() => {
                                                                                handleCopy(selectedRow?.message || '')
                                                                                setActiveTooltipKey(`message-${selectedRow?.id}`)
                                                                                setTimeout(() => {
                                                                                    setActiveTooltipKey('')
                                                                                }, 750)
                                                                            }}
                                                                        />
                                                                    </TooltipTrigger>
                                                                    <TooltipContent>
                                                                        <p>Copied</p>
                                                                    </TooltipContent>
                                                                </Tooltip>
                                                            </TooltipProvider>
                                                        </div>
                                                    </div>
                                                ) : selectedRow?.message.toLowerCase().includes('error')  ? (
                                                    <div className='flex items-center gap-2'>
                                                        <span className=''>
                                                            <svg
                                                                width='24'
                                                                height='24'
                                                                version='1.1'
                                                                viewBox='0 0 1200 1200'
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                className='rounded bg-white/80 fill-red-500 p-0.5 shadow'
                                                            >
                                                                <g fillRule='evenodd'>
                                                                    <path d='m270.68 498.66 68.766-68.766 68.766 68.766h-0.003906c10.344 10.34 25.418 14.383 39.547 10.594 14.129-3.7852 25.164-14.82 28.949-28.949 3.7852-14.129-0.25391-29.203-10.594-39.547l-68.766-68.766 68.766-68.766v0.003906c10.34-10.344 14.379-25.418 10.594-39.547-3.7852-14.129-14.82-25.164-28.949-28.949-14.129-3.7852-29.203 0.25391-39.547 10.598l-68.766 68.766-68.762-68.766c-10.344-10.344-25.418-14.383-39.547-10.598-14.129 3.7891-25.164 14.824-28.949 28.949-3.7852 14.129 0.25391 29.203 10.594 39.547l68.766 68.766-68.762 68.766c-10.344 10.34-14.383 25.418-10.598 39.547 3.7852 14.125 14.82 25.164 28.949 28.949 14.129 3.7852 29.203-0.25391 39.547-10.598z' />
                                                                    <path d='m918.45 371.99 68.766-68.766h-0.003906c10.344-10.344 14.383-25.418 10.598-39.547-3.7852-14.125-14.82-25.16-28.949-28.949-14.129-3.7852-29.203 0.25391-39.547 10.598l-68.762 68.766-68.766-68.766c-10.344-10.344-25.418-14.383-39.547-10.598-14.129 3.7891-25.164 14.824-28.949 28.949-3.7852 14.129 0.25391 29.203 10.594 39.547l68.766 68.766-68.766 68.766c-10.34 10.34-14.379 25.418-10.594 39.547 3.7852 14.125 14.82 25.164 28.949 28.949 14.129 3.7852 29.203-0.25391 39.547-10.598l68.766-68.766 68.766 68.766h-0.003906c10.344 10.344 25.418 14.383 39.547 10.598 14.129-3.7852 25.164-14.824 28.949-28.949 3.7891-14.129-0.25391-29.207-10.594-39.547z' />
                                                                    <path d='m600 721.87c-69.609-0.29297-138.32 15.773-200.58 46.906-62.262 31.133-116.34 76.461-157.87 132.33-6.5508 8.6836-9.375 19.617-7.8555 30.383 1.5234 10.77 7.2695 20.492 15.969 27.02 8.6992 6.5273 19.637 9.3242 30.402 7.7734 10.766-1.5469 20.473-7.3164 26.977-16.035 45.641-60.855 109.09-105.99 181.56-129.13 72.461-23.141 150.33-23.141 222.79 0 72.465 23.141 135.92 68.273 181.56 129.13 6.5039 8.7188 16.211 14.488 26.977 16.035 10.766 1.5508 21.703-1.2461 30.402-7.7734 8.6992-6.5273 14.445-16.25 15.969-27.02 1.5195-10.766-1.3047-21.699-7.8555-30.383-41.527-55.867-95.605-101.2-157.87-132.33-62.262-31.133-130.97-47.199-200.58-46.906z' />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <div className='flex w-full items-center justify-between'>
                                                            <span>{selectedRow?.message}</span>
                                                            <TooltipProvider>
                                                                <Tooltip open={activeTooltipKey === `message-${selectedRow?.id}`}>
                                                                    <TooltipTrigger>
                                                                        <CopyIcon
                                                                            className='text-gray-400 hover:cursor-pointer'
                                                                            size={16}
                                                                            onClick={() => {
                                                                                handleCopy(selectedRow?.message || '')
                                                                                setActiveTooltipKey(`message-${selectedRow?.id}`)
                                                                                setTimeout(() => {
                                                                                    setActiveTooltipKey('')
                                                                                }, 750)
                                                                            }}
                                                                        />
                                                                    </TooltipTrigger>
                                                                    <TooltipContent>
                                                                        <p>Copied</p>
                                                                    </TooltipContent>
                                                                </Tooltip>
                                                            </TooltipProvider>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <span className='text-gray-500'>{selectedRow?.message}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </CollapsibleContent>
                            </Collapsible>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4 rounded-md bg-white p-6 shadow sm:!max-w-4xl'>
                        <div className='flex w-full items-center justify-between'>
                            <h1 className='text-base font-bold text-gray-600' onClick={() => console.log(selectedRow?.data)}>
                                Data
                            </h1>
                            {Array.isArray(dialogTableData) && dialogTableData.length > 0 && (
                                <CsvDownloader filename={`${selectedRow?.connectorName}-${selectedRow?.id}.csv`} separator="," columns={dataColumns(dialogTableData[0])} datas={dialogTableData}>
                                    <Button variant='outline' className='ml-auto flex gap-2 text-gray-500'>
                                        Export Data <Download size={16} />
                                    </Button>
                                </CsvDownloader>
                            )}
                        </div>
                        <div className='max-h-72 w-full overflow-x-auto overflow-y-auto rounded-md py-0 scrollbar-thin '>
                            <DialogDataTable columns={dialogTableColumns} data={dialogTableData} />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            { (modelQuery.isLoading || etlLogsQuery.isLoading) &&
             <div className='flex h-full w-full flex-col gap-4 items-center justify-center'>
                <LogoSpinner />
            </div>
            }
            { !modelQuery.isLoading && !etlLogsQuery.isLoading &&
            <div className='flex h-full w-full flex-col gap-4 text-sm'>
                <DataTable columns={dataTableColumns} data={logs ?? []} onRowClick={row => handleRowClick(row.original)} pagination searchBar />
            </div>
            }
        </ModelDash>
    )
}

export default ModelJobsLogs
