import React, {useState} from 'react'
import { RenderingProps } from './RenderingSteps'
import {AddCircleOutline} from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {resource} from "selenium-webdriver/http";

interface Resources{
    label: string
    returnKey: string
}
const ChatRenderingParams: React.FC<RenderingProps> = ({ _model, stepIndex, onSaveChanges, onCancel }) => {
    const [columnLabelValue, setColumnLabelValue] = React.useState('')
    const [columnReturnValue, setColumnReturnValue] = React.useState('')
    const [params, setParams] = React.useState({
        predictionValueKey: 'value',
        returnKey: '',
        senderName: 'You',
        responderName: 'Summarizer',
        resources: [] as Resources[]
    })

    React.useEffect(() => {
        if (_model.config.params.renderingSteps) {
            if (stepIndex > -1) {
                setParams({
                    ...params,
                    ..._model.config.params.renderingSteps[stepIndex].params,
                })
            }
        }
    }, [stepIndex])

    if (!_model) {
        return null
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.type === 'checkbox') {
            setParams({ ...params, [event.target.name]: event.target.checked })
        } else {
            setParams({ ...params, [event.target.name]: event.target.value })
        }
    }

    const handleResourceFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({
            ...params,
            resources: {
                ...params.resources,
                [event.target.name]: event.target.value
            }
        })
    }

    const onAddResource = () => {
        setParams({
            ...params,
            resources: [...params.resources, {label: columnLabelValue, returnKey: columnReturnValue}]
        })
        setColumnLabelValue('')
        setColumnReturnValue('')
    }

    const onResourceNameInput = (event:any) =>{
        const { name, value } = event.target
        if(name === 'label'){
            setColumnLabelValue(value)
        } else if (name === 'returnKey') {
            setColumnReturnValue(value)
        }
    }

    const onRemoveResources = (index: number) =>{
        let _f = [...params.resources]
        _f.splice(index, 1)
        setParams({ ...params, resources: _f })
    }


    const onSubmit = () => {
        let _params: any = { ...params }
        onSaveChanges(_params)
    }

    return (
        <div className='flex flex-col gap-4'>
            <div>
                <p className='font-bold text-ai-700'>Chat Rendering</p>
                <p className='text-[14px] text-ai-700'>Used for configuring and rendering the chat bubbles within the Chatbot harvester</p>
            </div>
            <hr className='border-ai-200' />
            <p className='text-[14px] text-ai-700'>Rendering configuration</p>
            <div className='grid grid-cols-6 gap-2'>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Title for sender's bubble</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.senderName ?? ''}
                        name='senderName'
                    />
                </div>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Title for responder's bubble</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.responderName ?? ''}
                        name='responderName'
                    />
                </div>
            </div>
            <hr className='border-ai-200' />
            <p className='text-[14px] text-ai-700'>Data configuration</p>
            <div>
                <label htmlFor='return-key' className='block px-2 text-[13px] text-ai'>
                    Value Key
                </label>
                <input
                    className='input w-full'
                    id='return-key'
                    placeholder='results'
                    name='returnKey'
                    value={params.returnKey}
                    onChange={handleFieldsOnChange}
                />
                <p className='my-3 text-[13px] text-ai'>
                    Used for finding the response from the analytics results. Use{' '}
                    <span className='rounded-md bg-ai-200 py-[2px] px-2 font-bold'>"{' . '}"</span> for nested property keys. You can also
                    type the index if the value is within an array.
                    <br />
                    Example: <span className='rounded-md bg-ai-200 py-[2px] px-2 font-bold'>"{'results.0.text'}"</span>
                </p>
            </div>
            <hr className='border-ai-200' />
            <p className='text-[14px] text-ai-700'>Resources</p>
            <div className='flex flex-col gap-3 rounded-sm border border-ai-200 bg-ai-100 p-2'>
                <div className='flex items-center gap-2'>
                    <div className='flex-1'>
                        <input
                            name='label'
                            className='input w-full'
                            id='label'
                            type='text'
                            placeholder='label'
                            value={columnLabelValue}
                            onChange={onResourceNameInput}
                        />
                    </div>
                    <div className='flex-1'>
                        <input
                            name='returnKey'
                            className='input w-full'
                            id='return-key'
                            type='text'
                            placeholder='returnKey'
                            value={columnReturnValue}
                            onChange={onResourceNameInput}
                        />
                    </div>
                    <div>
                        <button
                            className=' btn-secondary relative flex h-[40px] items-center border border-ai p-3 text-[14px] font-bold'
                            onClick={onAddResource}
                        >
                            <AddCircleOutline sx={{ fontSize: '16px' }} />
                            <span className='px-1'>Add</span>
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <table aria-label='datasources' className='w-full border border-ai-200'>
                    <thead className='border-b border-b-ai-200 bg-white text-left'>
                    <tr>
                        <th className='w-[5%] px-4 py-2' scope='col'>
                            <span className='text-[16px] text-ai-800'>#</span>
                        </th>
                        <th className='w-[38%]'>
                            <span className='text-[16px] text-ai-800'>Column Name</span>
                        </th>
                        <th className='w-[38%]'>
                            <span className='text-[16px] text-ai-800'>Column Value</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody className='text-left'>
                    {params.resources.length === 0 ? (
                        <tr>
                            <td colSpan={4}>
                                <div className='w-full p-6 text-center font-[16px] text-ai-800'>
                                    There are no filters yet.
                                </div>
                            </td>
                        </tr>
                    ) : (
                        params.resources.map((resource, index) => (
                            <tr key={index} className={`${index % 2 === 0 ? 'bg-ai-50' : 'bg-ai-100'}`}>
                                <td className='p-4'>
                                    <span className='text-[16px] text-ai-800'>{index + 1}</span>
                                </td>
                                <td>
                                    <span className='text-ai-800'>{resource.label}</span>
                                </td>
                                <td>
                                    <span className='text-ai-800'>{resource.returnKey}</span>
                                </td>
                                <td className='pr-3'>
                                    <button
                                        className='btn-icon'
                                        onClick={() => onRemoveResources(index)}
                                        aria-label={`Remove filter ${index + 1}`}
                                        title='Delete Row'
                                    >
                                        <DeleteOutlineIcon color='error' />
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>
            <hr className='border-ai-200' />
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button className='btn-secondary border-none bg-transparent text-[#FF0000]' onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default ChatRenderingParams
