import React, { useEffect, useState } from 'react'
import { AnalyticsProps } from '../AnalyticsSteps'
import { AddCircleOutline } from '@mui/icons-material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useAnalyticPreview } from '../../../features/mutations'
import { JSONTree } from 'react-json-tree'
import { JSONTreeTheme } from '../../../Utils/constants'
import { useLayout, useResponse } from '../../../Store/Hooks'
import { ColumnFilter, defaultParams } from './ReverseMLUtils'
import 'react-js-cron/dist/styles.css'
import Checkbox from '../../Checkbox'
import CronJobSelect from '@/Components/CronJobSelect'

const SftpReadParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {
    const { setWarningResponse } = useResponse()
    const previewMutation = useAnalyticPreview()
    const { configEditing } = useLayout()
    const [connectorName, setConnectorName] = useState('')
    const [fileName, setFilename] = useState('')
    const [columnNameValue, setColumnNameValue] = useState('')
    const [columnValue, setColumnValue] = useState('')
    const [filterType, setFilterType] = useState('group')
    const [previewReady, setPreviewReady] = useState(false)
    const [scheduled, setScheduled] = useState(false)
    const [params, setParams] = useState({ ...defaultParams, connectorType: 'sftp', connectorAction: 'read' })

    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                const _params = _model.config.params.analytics[stepIndex].params
                setConnectorName(_model.config.params.analytics[stepIndex].label)
                setParams({
                    ...params,
                    ..._params,
                })
                if (_params.schedule) {
                    setScheduled(true)
                }
            }
        }
    }, [stepIndex])

    useEffect(() => {
        if (params.connectorType === 'sftp' && params.fileNames.length > 0) {
            setPreviewReady(true)
        } else {
            setPreviewReady(false)
        }
    }, [params])

    if (!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const fetchPreviewData = (e: any) => {
        e.preventDefault()
        if (previewReady) {
            previewMutation.mutate({
                modelId: _model.id === 'new' ? undefined : _model.id, step: { className: 'ReverseMLWorkflow', params, label: connectorName }
            })
        }
    }

    const onAddFilename = () => {
        if (fileName !== '') {
            let _p = { ...params }
            let files = fileName.split(',')
            if (!_p.fileNames) {
                _p.fileNames = []
            }
            setParams({ ..._p, fileNames: [..._p.fileNames, ...files] })
            setFilename('')
        }
    }

    const onAddFilterName = () => {
        if (columnNameValue !== '') {
            let newFilter: ColumnFilter
            if (filterType === 'input') {
                newFilter = {
                    inputType: 'input',
                    columnName: columnNameValue,
                    columnValue: 'N/A',
                }
            } else if (filterType === 'static') {
                newFilter = {
                    inputType: 'static',
                    columnName: columnNameValue,
                    columnValue: columnValue,
                }
            } else {
                if (columnValue === '') {
                    return
                }
                newFilter = {
                    inputType: 'group',
                    columnName: columnNameValue,
                    columnValue: columnValue,
                }
            }
            setParams({
                ...params,
                filterByColumns: [...params.filterByColumns, newFilter],
            })
            setColumnNameValue('')
            setColumnValue('')
        }
    }

    const onFilterNameInput = (e: any) => {
        const { name, value } = e.target
        if (name === 'column_name') {
            setColumnNameValue(value)
            if (filterType === 'input') {
                setColumnValue('')
            }
        } else if (name === 'column_value') {
            setColumnValue(value)
        }
    }

    const onRemoveColumnFilter = (index: number) => {
        let _f = [...params.filterByColumns]
        _f.splice(index, 1)
        setParams({ ...params, filterByColumns: _f })
    }

    const onRemoveFilename = (index: number) => {
        let _f = [...params.fileNames]
        _f.splice(index, 1)
        setParams({
            ...params,
            fileNames: _f,
        })
    }

    const onFilenameInput = (e: any) => {
        setFilename(e.target.value)
    }

    const handleFilterTypeChange = (e: any) => {
        setFilterType(e.target.value)
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onScheduledChange = (e: any) => {
        if (!e.target.checked) {
            setParams({ ...params, schedule: null })
        } else {
            setParams({ ...params, schedule: { timezone: '', cron: '* * * * *' } })
        }
        setScheduled(!scheduled)
    }

    const handleTimezoneOnChange = (value: string) => {
        if(!params.schedule) {
            setParams({ ...params, schedule: { timezone: value, cron: '' } })
        } else {
            setParams({ ...params, schedule: { ...params.schedule, timezone: value } })
        }
    }

    const handleCronOnChange = (value: string) => {
        if(!params.schedule) {
            setParams({ ...params, schedule: { timezone: '', cron: value } })
        } else {
            setParams({ ...params, schedule: { ...params.schedule, cron: value } })
        }
    }

    const onSubmit = () => {
        let _params = { ...params }
        if (_params.connectorType === 'sftp' && _params.connectorAction === 'read') {
            if (
                connectorName.length === 0 ||
                _params.host.length === 0 ||
                _params.port.length === 0 ||
                _params.dbUser.length === 0 ||
                _params.password.length === 0 ||
                _params.path.length === 0 ||
                _params.fileNames.length === 0
            ) {
                setWarningResponse(
                    'Required Fields',
                    `Please complete all required fields: ${['Name', 'URL', 'Port', 'Username', 'Password', 'Path', 'Filenames']
                        .join(', ')
                        .replace(/, (?=[^,]*$)/, ' & ')}.`,
                )
                return
            }
        }
        onSaveChanges(connectorName, _params)
    }

    return (
        <div className='flex flex-col gap-4'>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name*</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                    disabled={stepIndex > -1}
                />
            </div>
            <div className='grid grid-cols-6 gap-2'>
                <div className='col-span-full xl:col-span-4'>
                    <label className='px-2 text-[13px] text-ai'>URL*</label>
                    <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.host ?? ''} name='host' />
                </div>
                <div className='col-span-full xl:col-span-2'>
                    <label className='px-2 text-[13px] text-ai'>Port*</label>
                    <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.port ?? ''} name='port' />
                </div>
            </div>
            <div className='grid grid-cols-6 gap-2'>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Username*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.dbUser ?? ''}
                        name='dbUser'
                    />
                </div>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Password*</label>
                    <input
                        className='input w-full'
                        type={'password'}
                        onChange={e => {
                            handleFieldsOnChange(e)
                        }}
                        value={params.password ?? ''}
                        name='password'
                    />
                </div>
            </div>
            <div>
                <label className='px-2 text-[13px] text-ai'>Path*</label>
                <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.path ?? ''} name='path' />
            </div>

            <hr className='border-ai-200' />

            <p className='font-bold text-ai-700'>File names</p>
            <div className='flex flex-col gap-3 rounded-sm border border-ai-200 bg-ai-100 p-2'>
                <div className='flex items-center gap-2'>
                    <div className='flex-1'>
                        <label className='block px-2 text-[13px] text-ai'>File</label>
                        <input
                            className='input w-full'
                            type='text'
                            placeholder='SOS_file_name.csv'
                            value={fileName}
                            onChange={onFilenameInput}
                        />
                    </div>
                    <div>
                        <button
                            className='btn-secondary relative top-[10px] flex h-[40px] items-center border border-ai p-3 text-[14px] font-bold'
                            onClick={onAddFilename}
                        >
                            <AddCircleOutline sx={{ fontSize: '16px' }} />
                            <span className='px-1'>Add</span>
                        </button>
                    </div>
                </div>
                <div>
                    <table aria-label='datasources' className='border border-ai-200'>
                        <thead className='border-b border-b-ai-200 bg-white text-left'>
                            <tr>
                                <th className='w-1 px-4 py-2'>
                                    <span className='text-[16px]  text-ai-800'>#</span>
                                </th>
                                <th className='w-full'>
                                    <span className='text-[16px]  text-ai-800'>Value</span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className='text-left'>
                            {params.fileNames && params.fileNames.length === 0 && (
                                <tr>
                                    <td colSpan={4}>
                                        <div className='w-full p-6 text-center font-[16px] text-ai-800'>There are no file names yet.</div>
                                    </td>
                                </tr>
                            )}

                            {params.fileNames &&
                                params.fileNames.map((source: any, index: number) => {
                                    return (
                                        <tr key={source.predictionNameKey + index} className={'bg-ai-50'}>
                                            <td className='p-4'>
                                                <span className=' text-ai-800'>{index + 1 + '.'}</span>
                                            </td>
                                            <td>
                                                <span className=' text-ai-800'>{source}</span>
                                            </td>
                                            <td>
                                                <button className='btn-icon' onClick={() => onRemoveFilename(index)} title='Delete Row'>
                                                    <DeleteOutlineIcon color='error' />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
            <hr className='border-ai-200' />
            <div>
                <button disabled={!previewReady} className='btn-secondary py-1 px-2 text-sm' onClick={fetchPreviewData}>
                    Fetch Preview Data
                </button>
            </div>
            <div className='flex w-full flex-col items-center justify-center'>
                {previewMutation.isLoading && <p className='font-[20px] text-ai'>Fetching data preview...</p>}
                {previewMutation.isError && (
                    <p className='font-[20px] text-ai'>Could not retrieve preview of the data - Review connector parameters</p>
                )}
                {!previewMutation.isLoading && configEditing.analyticsPreview && (
                    <div className='w-full flex-1 bg-white p-4'>
                        <p className='font-bold text-ai-700'>Data Sample Preview: </p>
                        <JSONTree
                            shouldExpandNode={() => {
                                return true
                            }}
                            theme={JSONTreeTheme}
                            data={configEditing.analyticsPreview ?? {}}
                        />
                    </div>
                )}
            </div>
            {(_model.config.params.analytics?.length === 0 || stepIndex === 0) &&
                <>
                    <hr className='border-ai-200' />
                    <div className='flex flex-1 gap-2'>
                        <Checkbox checked={scheduled} onChange={onScheduledChange} />
                        <label className='block px-2 text-ai'>Schedule ETL?</label>
                    </div>
                    {scheduled && (
                        <CronJobSelect timezone={params.schedule?.timezone ?? ''} schedule={params.schedule?.cron ?? ''} onScheduleChange={handleCronOnChange} onTimezoneChange={handleTimezoneOnChange}/>
                    )}
                </>
            }
            <hr className='border-ai-200' />
            {/* footer */}
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button className='btn-secondary border-none bg-transparent text-[#FF0000]' onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default SftpReadParams
