import React, { useEffect, useState } from 'react'
import { AnalyticsProps } from "./AnalyticsSteps"
import { useResponse } from '../../Store/Hooks'
import { Editor } from '@monaco-editor/react'

const options = {
    readOnly: false,
    minimap: { enabled: false },
}

const DeployedAnalyticsParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex}) => {

    const { setWarningResponse } = useResponse()
    const [connectorName, setConnectorName] = useState('')
    const [params, setParams] = useState({
        method: 'POST',
        url: '',
        inputType: 'text',
        headers: {accept: 'application/json'},
        body: {}
    })
    const [headers, setHeaders] = useState('')
    const [body, setBody] = useState('')
    const [key, setKey] = useState(0)

    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                const _params = {..._model.config.params.analytics[stepIndex].params}
                if(_params.headers) {
                    try {
                        const h = JSON.stringify(_params.headers)
                        setHeaders(h)
                    } catch { }
                }
                if(_params.body) {
                    try {
                        const b = JSON.stringify(_params.body)
                        setBody(b)
                    } catch { }
                }
                setParams({
                    ...params,
                    ..._params
                })
                setConnectorName(_model.config.params.analytics[stepIndex].label)
                setKey(key + 1)
            }
        }
    }, [stepIndex])

    useEffect(() => {
        if(params.method === 'GET') {
            setBody('')
            setParams({...params, body: {}})
        }
    }, [params.method])

    if(!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onSubmit = () => {
        let _params: any = {...params}
        _params.headers = null
        _params.body = null
        if (connectorName.length === 0, _params.url.length === 0, _params.method.length === 0) {
            setWarningResponse('Required Field', `Please complete all required fields: ${['Name', 'URL', 'Method'].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`)
            return
        }
        if(headers.length > 0) {
            try{
                _params.headers = JSON.parse(headers)
            }catch {
                setWarningResponse('Invalid Header', `Plase enter a valid header JSON.`)
                return
            }
        }
        if(_params.method === 'POST' && body.length > 0) {
            try{
                _params.body = JSON.parse(body)
            }catch {
                setWarningResponse('Invalid Body', `Plase enter a valid body JSON.`)
                return
            }
        }
        onSaveChanges(connectorName, { ..._params })
    }

    return (
        <div className="flex flex-col gap-4">
            <p className='text-ai-700 font-bold'>
                Deployed Model
            </p>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                />
            </div>
            <hr className="border-ai-200"/>
            <div>
                <label className="text-ai text-[13px] px-2">URL*</label>
                <input className="input w-full" type={"text"} onChange={handleFieldsOnChange} value={params.url ?? ''} placeholder="URL for fetching model result" name="url" />
            </div>
            <div>
                <p className="text-ai text-[13px] px-2">Method*</p>
                <div className="flex gap-5">
                    <div className="relative flex items-center">
                        <input
                            className="radio-parent peer"
                            name="method"
                            type="radio"
                            value="POST"
                            checked={params.method === "POST"}
                            onChange={handleFieldsOnChange}
                        />
                        <div className="radio" />
                        <label htmlFor="" className="pl-3 truncate">
                            POST
                        </label>
                    </div>
                    <div className="relative flex items-center">
                        <input
                            className="radio-parent peer"
                            name="method"
                            type="radio"
                            value="GET"
                            checked={params.method === "GET"}
                            onChange={handleFieldsOnChange}
                        />
                        <div className="radio" />
                        <label htmlFor="" className="pl-3 truncate">
                            GET
                        </label>
                    </div>
                </div>
            </div>
            <hr className="border-ai-200"/>
            <div>
                <div>
                    <label className="text-ai text-[13px] px-2">Headers*</label>
                    <Editor
                        key={key}
                        options={options}
                        height='200px'
                        defaultLanguage={'json'}
                        defaultValue={JSON.stringify(params.headers) || ''}
                        onChange={(json) => {
                            setHeaders(json ?? '')
                        }}
                    />
                </div>
                {params.method === 'POST' && 
                <div>
                    <label className="text-ai text-[13px] px-2">Body*</label>
                    <Editor
                        key={key + 1}
                        options={options}
                        height='200px'
                        defaultLanguage={'json'}
                        defaultValue={JSON.stringify(params.body) || ''}
                        onChange={(json) => {
                            setBody(json ?? '')
                        }}
                    />
                </div>
                }
                <p className='my-3 text-[13px] text-ai'>
                    Use <span className='rounded-md bg-ai-200 py-[2px] px-2 font-bold'>"{"{{input}}"}"</span> variable to use harvested data value.
                </p>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    )
}

export default DeployedAnalyticsParams