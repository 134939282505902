import React, { useState } from 'react'
import {
    MoveUpOutlined,
    Code,
    FileDownloadOutlined,
    PollOutlined,
    Upgrade,
    PersonAddAltOutlined,
    FileCopyOutlined,
    DeleteOutlineOutlined,
    MoreVertOutlined,
} from '@mui/icons-material'
import { useLayout } from '../../Store/Hooks'
import { useNavigate } from 'react-router-dom'
import { ROUTER_PATHS } from '../../Utils/constants'
import { useGetModel } from '../../features/queries'
import { useDownloadModel, useConfigUpdate } from '../../features/mutations'
import { AIRModel, ModelConfigRoot } from '../../features/types'
import ProtectedAction from '../../Layouts/ProtectedAction'

interface ModelItemProps {
    model: AIRModel
    configDidChange?: (config: ModelConfigRoot) => void
}

const ModelItem: React.FC<ModelItemProps> = ({ model, configDidChange }) => {
    const navigate = useNavigate()
    const configMutation = useConfigUpdate()
    const downloadMutation = useDownloadModel()
    const {
        openClonePopup,
        openUpgradePopup,
        openPromotePopup,
        openDeletePopup,
    } = useLayout()

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isToggleOn, setIsToggleOn] = useState(false)

    React.useEffect(() => {
        if (model) {
            if (
                model.config.params.autoRun !== undefined ||
                model.config.params.autoRun !== null
            ) {
                setIsToggleOn(model.config.params.autoRun === 'true')
            }
        }
    }, [model])

    const onConfigEdit = (id: string) => {
        navigate(ROUTER_PATHS.workspaceEdit.replace(':id', id))
    }

    const onShareModel = (id: string) => {
        navigate(ROUTER_PATHS.model.replace(':id', id) + '/permissions')
    }

    const onAnalytics = (id: string) => {
        const subpath = model.config.params.analytics && model.config.params.analytics.length > 1 ? '/jobs' : '/insights'
        navigate(ROUTER_PATHS.model.replace(':id', id) + subpath)
    }

    const onDownloadConfig = () => {
        downloadMutation.model.mutate({
            modelId: model.id,
            name: model.config.params.name,
        })
    }

    const onCloneSelect = () => {
        openClonePopup({
            open: true,
            model,
        })
    }

    const onDeleteSelect = () => {
        openDeletePopup({ open: true, model })
    }

    const onPromoteDialog = () => {
        openPromotePopup({
            open: true,
            stage: 'staging',
            model: model,
        })
    }

    const onUpgradeDialog = () => {
        openUpgradePopup({
            open: true,
            model,
        })
    }

    const onAutorunChange = () => {
        const config = {
            ...model.config,
            params: {
                ...model.config.params,
                autoRun:
                    model.config.params.autoRun === 'true' ? 'false' : 'true',
            },
        }
        configMutation.mutate({ modelId: model.id, config, upload: true })
        if (configDidChange) {
            configDidChange(config)
        }
    }

    if (!model || !model.config) {
        return null
    }

    return (
        <div className='md:max-wd relative flex w-full flex-col overflow-hidden rounded-lg bg-white shadow-sm lg:max-w-lg 2xl:max-w-full'>
            <div className='flex flex-1 flex-col'>
                <div className='flex justify-between px-6 py-4'>
                    <div
                        className='flex-1 cursor-pointer truncate text-lg font-bold text-ai decoration-2 underline-offset-4 hover:underline'
                        onClick={onConfigEdit.bind(this, model.id)}
                        title='Edit config'
                    >
                        {model.config.params.name}
                    </div>
                    <div
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        onBlur={() => setIsMenuOpen(false)}
                        tabIndex={1}
                        className='relative'
                    >
                        <MoreVertOutlined className='cursor-pointer text-ai' />

                        {isMenuOpen && (
                            <ul className='absolute right-0 z-10  w-max select-none rounded-md border  border-ai-200 bg-white text-sm shadow-sm'>
                                <li
                                    onClick={onCloneSelect}
                                    className='cursor-pointer px-4 py-2 hover:bg-ai-50'
                                >
                                    <FileCopyOutlined className='mr-2  text-ai' />
                                    Clone Model
                                </li>
                                <li
                                    onClick={onUpgradeDialog.bind(this)}
                                    className='cursor-pointer px-4 py-2 hover:bg-ai-50'
                                >
                                    <Upgrade className='mr-2  text-ai' />
                                    Upgrade Version
                                </li>
                                {model.isOwner &&
                                    model.config.params.stage ===
                                        'experimental' && (
                                        <ProtectedAction permission='delete model'>
                                            <li
                                                onClick={onDeleteSelect.bind(this)}
                                                className='cursor-pointer px-4 py-2 hover:bg-ai-50'
                                            >
                                                <DeleteOutlineOutlined className='mr-2  text-ai' />
                                                Delete Model
                                            </li>
                                        </ProtectedAction>
                                    )}
                            </ul>
                        )}
                    </div>
                </div>
                <hr className='border-ai-200' />
                <div className='h-full flex flex-col gap-3 px-6 py-2'>
                    <div className='flex-1 space-y-2'>
                        <p
                            className='h-24 text-sm text-ai-800 line-clamp-5 text-justify'
                            title={model.config.params.description}
                        >
                            {model.config.params.description && model.config.params.description.length > 0 ? model.config.params.description : 'No description'}
                        </p>
                    </div>
                    <div className='space-y-4'>
                        <div className='flex items-end'>
                            <div className='flex flex-1 gap-6'>
                                <div className='text-sm'>
                                    <p className='font-bold text-ai-700'>
                                        {model.config.params.author && model.config.params.author.length > 0 ? model.config.params.author : 'No Author'}
                                    </p>
                                    <p className='capitalize'>
                                        {model.config.params.version ?? '1'} -{' '}
                                        {model.config.params.stage}{' '}
                                    </p>
                                </div>
                            </div>
                            <ProtectedAction permission={'update model'}>
                                {model.config.params.autoRun !== undefined && (
                                    <div className='float-right flex flex-col items-end space-x-2 text-sm'>
                                        <p className='text-sm text-ai-800 relative -top-1'>
                                            Autorun?
                                        </p>

                                        <div
                                            className={`flex h-4 w-8 cursor-pointer items-center rounded-full p-1 ${
                                                isToggleOn
                                                    ? 'bg-ai'
                                                    : 'bg-ai-200'
                                            }`}
                                            onClick={e => {
                                                setIsToggleOn(!isToggleOn)
                                                onAutorunChange()
                                            }}
                                        >
                                            <div
                                                className={`h-3 w-3 transform rounded-full bg-white shadow-md transition duration-150 ease-in-out ${
                                                    isToggleOn
                                                        ? 'translate-x-3'
                                                        : null
                                                }`}
                                            ></div>
                                        </div>
                                    </div>
                                )}
                            </ProtectedAction>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex space-x-2 border-t border-ai-200 bg-ai-100 px-6 py-2 text-ai'>
                <ProtectedAction permission='update model'>
                    {!model.airjsDefault &&
                        model.config.params.stage === 'experimental' && (
                            <div
                                className='cursor-pointer'
                                onClick={onConfigEdit.bind(this, model.id)}
                                title='Edit config'
                            >
                                <Code />
                            </div>
                        )}
                </ProtectedAction>
                <ProtectedAction permission='read model'>
                    <div
                        className='cursor-pointer'
                        onClick={onAnalytics.bind(this, model.id)}
                        title='Insights'
                    >
                        <PollOutlined />
                    </div>
                </ProtectedAction>

                {model.isOwner && (
                    <ProtectedAction permission={['user share list model', '']}>
                        <div
                            className='cursor-pointer'
                            onClick={onShareModel.bind(this, model.id)}
                            title='Share Settings'
                        >
                            <PersonAddAltOutlined />
                        </div>
                    </ProtectedAction>
                )}

                {!model.airjsDefault && (
                    <div
                        className='cursor-pointer'
                        onClick={onDownloadConfig}
                        title='Download'
                    >
                        <FileDownloadOutlined />
                    </div>
                )}

                {!model.airjsDefault &&
                    model.isOwner &&
                    model.config.params.stage === 'experimental' && (
                        <div
                            className='-scale-x-100 transform cursor-pointer'
                            onClick={onPromoteDialog.bind(this)}
                            title='Promote to Registry'
                        >
                            <MoveUpOutlined />
                        </div>
                    )}
            </div>
        </div>
    )
}

export default ModelItem
