import { useQuery } from "@tanstack/react-query"
import axios from "axios";
import { endpoints } from "../endpoints";
import { ETLJobStatus } from "../types";

const useGetJobs = (modelId?: string) => {
    return useQuery(["etlStatus", modelId], async () => {
        const token = localStorage.getItem("token");
        const authtype = localStorage.getItem("authtype");
        if (token && authtype) {
            const options = {
                headers: {
                    authtype,
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await axios.get<{data: ETLJobStatus[]}>(`${endpoints.etlStatus}/${modelId}`, options);
            return response.data.data;
        }

    }, {
        enabled: !!modelId
    })
}

export default useGetJobs
