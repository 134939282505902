import React from "react";
import DocumentRenderingParams from "./DocumentRenderingParams";
import ImageRenderingParams from "./ImageRenderingParams";
import ObjectRenderingParams from "./ObjectRenderingParams";
import WordRenderingParams from "./WordRenderingParams";
import { swapItems, validateRequiredFields } from "../../Utils/utils";
import { useResponse } from "../../Store/Hooks";
import LeadNameRenderingParams from "./LeadNameRenderingParams";
import LeadNameDashboardRenderingParams from "./LeadNameDashboardRenderingParams";
import { AIRModel, BasicStepsProps } from "../../features/types";
import {
    ArrowDownward,
    ArrowUpward,
    ContentCopy,
    Edit,
    EditOutlined,
    Remove,
} from "@mui/icons-material";
import ContainerRenderingParams from "./ContainerRenderingParams";
import PieChartRenderingParams from "./PieChartRenderingParams";
import DoughnutChartRenderingParams from "./DoughnutChartRenderingParams";
import BarChartRenderingParams from "./BarChartRenderingParams";
import LineChartRenderingParams from "./LineChartRenderingParams";
import HtmlTagRenderingParams from "./HtmlTagRenderingParams";
import TableRenderingParams from "./TableRenderingParams";
import DashboardReplacementRenderingParams from "./DashboardReplacementRenderingParams";
import Dropdown from "../Dropdown";
import RemoteRenderingParams from "./RemoteRenderingParams";
import CustomRenderingParams from "./CustomRenderingParams";
import ChatRenderingParams from "./ChatRenderingParams";

export interface RenderingProps {
    _model: AIRModel;
    stepIndex: number;
    onSaveChanges: (params: any) => void;
    onCancel: (params: any) => void;
}

export const renderingColors = [
    "red",
    "orange",
    "green",
    "purple",
    "yellow",
    "pink",
    "black",
    "grey",
    "white",
    "blue",
];

export const badgeShapes = ["circle", "square", "star", "underline"];

const renderingOptions = [
    // {
    //     option: "Document Rendering",
    //     className: "DocumentRendering",
    //     required: ["predictionKey", "probabilityKey", "underlineColor"],
    // },
    {
        option: "Image Rendering",
        className: "ImageRendering",
        required: [
            "color",
            "thickness",
            "placement",
            "badgeColor",
            "fontColor",
            "fontSize",
        ],
    },
    {
        option: "Object Rendering",
        className: "ObjectRendering",
        required: [
            "color",
            "thickness",
            "placement",
            "badgeColor",
            "fontColor",
            "fontSize",
        ],
    },
    {
        option: "Word Rendering",
        className: "WordRendering",
        required: [
            "wordList",
            "resultKey",
            "badgeShape",
            "badgeColor",
        ],
    },
    {
        option: "Lead Name Rendering",
        className: "LeadNameRendering",
        required: [],
    },
    {
        option: "Lead Name Dashboard Rendering",
        className: "LeadNameDashboardRendering",
        required: [],
    },
    {
        option: "Dashboard Replacement Rendering",
        className: "DashboardReplacementRendering",
        required: [],
    },
    {
        option: "Container Rendering",
        className: "ContainerRendering",
        required: ["id", "position", "xOffset", "yOffset", "height", "width"],
    },
    {
        option: "Pie Chart Rendering",
        className: "PieChartRendering",
        required: ["id", "chartName"],
    },
    {
        option: "Doughnut Chart Rendering",
        className: "DoughnutChartRendering",
        required: ["id", "chartName"],
    },
    {
        option: "Bar Chart Rendering",
        className: "BarChartRendering",
        required: ["id", "chartName"],
    },
    {
        option: "Line Chart Rendering",
        className: "LineChartRendering",
        required: ["id", "chartName"],
    },
    {
        option: "HTML Tag Rendering",
        className: "HTMLTagRendering",
        required: ["id", "htmlContent"],
    },
    {
        option: "Table Rendering",
        className: "TableRendering",
        required: [],
    },
    {
        option: "Remote Rendering",
        className: "RemoteRendering",
        required: ["id", "src"],
    },
    {
        option: "Custom Rendering",
        className: "CustomRendering",
        required: ["id", "contentHtml", "contentScript", "contentStyle"],
    },
    {
        option: "Chat Rendering",
        className: "ChatRendering",
        required: ["predictionValueKey", "returnKey"],
    }
];

const renderingClassComponents = {
    DocumentRendering: DocumentRenderingParams,
    ImageRendering: ImageRenderingParams,
    ObjectRendering: ObjectRenderingParams,
    WordRendering: WordRenderingParams,
    LeadNameRendering: LeadNameRenderingParams,
    LeadNameDashboardRendering: LeadNameDashboardRenderingParams,
    DashboardReplacementRendering: DashboardReplacementRenderingParams,
    ContainerRendering: ContainerRenderingParams,
    PieChartRendering: PieChartRenderingParams,
    DoughnutChartRendering: DoughnutChartRenderingParams,
    BarChartRendering: BarChartRenderingParams,
    LineChartRendering: LineChartRenderingParams,
    HTMLTagRendering: HtmlTagRenderingParams,
    TableRendering: TableRenderingParams,
    RemoteRendering: RemoteRenderingParams,
    CustomRendering: CustomRenderingParams,
    ChatRendering: ChatRenderingParams
};

type ObjectKey = keyof typeof renderingClassComponents;

const RenderingSteps: React.FC<BasicStepsProps> = ({ _model, onConfigChange }) => {
    const { setWarningResponse } = useResponse();
    const [optionClass, setOptionClass] = React.useState("");
    const [selectedOpt, setSelectedOpt] = React.useState<any>(null);
    const [editIndex, setEditIndex] = React.useState(-1);
    const [editLabelIndex, setEditLabelIndex] = React.useState(-1);
    const [label, setLabel] = React.useState("");

    if (!_model) {
        return null;
    }

    const onOptionChange = (event: any) => {
        setEditIndex(-1);
        let selectedIndex = Number(event.target.value);
        setOptionClass(renderingOptions[selectedIndex].className);
        const selected = renderingOptions.filter(
            (a) => a.className === renderingOptions[selectedIndex].className
        )[0];
        setSelectedOpt(selected);
    };

    const onSaveChanges = (params: any) => {
        let required = selectedOpt?.required;
        const steps = _model.config.params.renderingSteps ?? [];
        if (!required && editIndex > -1) {
            const possibleOption = renderingOptions.find(
                (opts) => opts.className === steps[editIndex].className
            );
            if (possibleOption) {
                required = possibleOption.required;
            }
        }
        if (!validateRequiredFields(params, required)) {
            setWarningResponse(
                "Required Fields",
                `Please complete all required fields: ${required.join(
                    ", "
                ).replace(/, (?=[^,]*$)/, ' & ')}`
            );
            return;
        }
        let config = { ..._model.config };
        let renderingSteps = config.params.renderingSteps ?? [];
        if (editIndex > -1) {
            renderingSteps[editIndex].params = params;
            setEditIndex(-1);
        } else {
            renderingSteps.push({ className: optionClass, params });
            setOptionClass("");
        }
        config.params.renderingSteps = renderingSteps;
        onConfigChange(config)
    };

    const onCloneStep = (index: number) => {
        let steps = _model.config.params.renderingSteps
            ? [..._model.config.params.renderingSteps]
            : [];
        let step = { ...steps[index] }
        steps = [...steps, step]
        let config = { ..._model.config };
        config.params.renderingSteps = steps;
        onConfigChange(config)
    }

    const onRemoveClass = (index: number) => {
        let steps = _model.config.params.renderingSteps
            ? [..._model.config.params.renderingSteps]
            : [];
        steps.splice(index, 1);
        let config = { ..._model.config };
        config.params.renderingSteps = steps;
        onConfigChange(config)
        if (index === editIndex) {
            setEditIndex(-1);
        }
    };

    const onSaveClassLabel = () => {
        let config = { ..._model.config };
        if (config.params.renderingSteps) {
            config.params.renderingSteps[editLabelIndex].label = label;
            setEditLabelIndex(-1);
            onConfigChange(config)
        }
    };

    const onStepSwap = (index: number, withIndex: number) => {
        let m = { ..._model };
        if (m.config.params.renderingSteps) {
            let steps = swapItems(
                m.config.params.renderingSteps,
                index,
                withIndex
            );
            m.config.params.renderingSteps = steps;
            onConfigChange(m.config)
        }
    };

    const renderParamsComponent = () => {
        const Component = renderingClassComponents[optionClass as ObjectKey];
        if (Component) {
            return (
                <Component
                    _model={_model}
                    stepIndex={editIndex}
                    onSaveChanges={onSaveChanges}
                    onCancel={() => {
                        setSelectedOpt(null);
                        setOptionClass("");
                        setEditIndex(-1);
                    }}
                />
            );
        }
        return null;
    };

    const renderEditParamsComponent = () => {
        if(_model.config.params.renderingSteps && editIndex > -1) {
            const Component = renderingClassComponents[(_model.config.params.renderingSteps[editIndex].className as ObjectKey)];
            if (Component) {
                return (
                    <Component
                        _model={_model}
                        stepIndex={editIndex}
                        onSaveChanges={onSaveChanges}
                        onCancel={() => {
                            setSelectedOpt(null);
                            setOptionClass("");
                            setEditIndex(-1);
                        }}
                    />
                );
            }
        }
        return null;
    }

    const renderingSteps = _model.config.params.renderingSteps ?? [];
    const selectedClassIndex = optionClass.length > 0 ? renderingOptions.findIndex((a) => a.className === optionClass) : -1

    return (
        <div>
            <p className="text-ai-700 text-[16px] font-bold mb-2">Rendering class</p>
            <Dropdown
                items={renderingOptions.map((opt) => opt.option) as string[]}
                selectedIndex={selectedClassIndex}
                handleDropdownChange={onOptionChange}
                size="w-1/2"
                placeholder={"Choose rendering class"}
            />
            <div className="my-6 flex flex-col gap-2 bg-white p-4 rounded-md">
                {renderParamsComponent()}
            </div>
            <div>
                <table aria-label="Rendering steps" className="w-full border border-gray-300">
                    <thead className="bg-white text-left border-b border-b-gray-300">
                        <tr>
                            <th className="px-4 py-2 w-1">
                                <span className="text-[16px] text-ai-800 ">#</span>
                            </th>
                            <th>
                                <span className="text-[16px] text-ai-800 ">Label</span>
                            </th>
                            <th>
                                <span className="text-[16px] text-ai-800 ">Class name</span>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="text-left">
                        {
                            renderingSteps.length === 0 &&
                            <tr>
                                <td colSpan={7}>
                                    <div className="w-full p-6 text-ai-800 text-center font-[16px]">
                                        There are no classes yet.
                                    </div>
                                </td>
                            </tr>
                        }
                        {renderingSteps.map((step: any, index: number) => {
                            return (
                                <React.Fragment key={step.className + index}>
                                    <tr className={index % 2 === 0 ? 'bg-ai-200' : ''}>
                                        <td className="p-4">
                                            <span className="text-ai-800 ">
                                                {index + 1 + "."}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="text-ai-800 ">
                                                {editLabelIndex === index && (
                                                    <div className="flex">
                                                        <input className="input w-full"
                                                            value={label}
                                                            onChange={(e) => {
                                                                setLabel(
                                                                    e.target.value
                                                                );
                                                            }}
                                                        />
                                                        <button className="btn-icon"
                                                            onClick={
                                                                onSaveClassLabel
                                                            }
                                                        >
                                                            <Edit fontSize="small" />
                                                        </button>
                                                    </div>
                                                )}
                                                {editLabelIndex !== index ? (
                                                    <span className="hover:underline hover:text-ai cursor-pointer"
                                                        onClick={(e: any) => {
                                                            e.preventDefault()
                                                            if(_model.config.params.renderingSteps){
                                                                setLabel(_model.config.params.renderingSteps[index].label)
                                                            }
                                                            setEditLabelIndex(index);
                                                        }}
                                                    >
                                                        {step.label ?? "N/A"}
                                                    </span>
                                                ) : null}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="text-ai-800 ">
                                                {step.className}
                                            </span>
                                        </td>
                                        <td>
                                            {index !== 0 && (
                                                <button className="text-ai btn-icon"
                                                    onClick={() => {
                                                        onStepSwap(
                                                            index,
                                                            index - 1
                                                        );
                                                    }}
                                                >
                                                    <ArrowUpward fontSize="small" />
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            {index !==
                                                renderingSteps.length - 1 && (
                                                <button className="text-ai btn-icon"
                                                    onClick={() => {
                                                        onStepSwap(
                                                            index,
                                                            index + 1
                                                        );
                                                    }}
                                                >
                                                    <ArrowDownward fontSize="small" />
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            <button className="btn-icon"
                                                onClick={() => {
                                                    setSelectedOpt(null);
                                                    setOptionClass("");
                                                    if(editIndex === index) {
                                                        setEditIndex(-1);
                                                    } else {
                                                        setEditIndex(index);
                                                    }
                                                }}
                                            >
                                                <EditOutlined fontSize="small" />
                                            </button>
                                        </td>
                                        <td>
                                            <button className="btn-icon"
                                                onClick={() => {
                                                    onCloneStep(index);
                                                }}
                                            >
                                                <ContentCopy fontSize="small" />
                                            </button>
                                        </td>
                                        <td>
                                            <button className="btn-icon"
                                                onClick={() => {
                                                    onRemoveClass(index);
                                                }}
                                            >
                                                <Remove color="error" />
                                            </button>
                                        </td>
                                    </tr>
                                    {editIndex > -1 && editIndex === index &&
                                    <tr key={step.className + index + '-edit-view'}>
                                        <td colSpan={8}>
                                            <div className="p-3 rounded bg-white">
                                                {renderEditParamsComponent()}
                                            </div>
                                        </td>
                                    </tr>}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RenderingSteps;
