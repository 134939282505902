import React, { useEffect, useRef, useState } from 'react'
import { AnalyticsProps } from '../AnalyticsSteps'
import { AddCircleOutline, DeleteOutline } from '@mui/icons-material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useResponse } from '../../../Store/Hooks'
import { ColumnFilter, DataMap, defaultParams } from './ReverseMLUtils'
import 'react-js-cron/dist/styles.css'
import Checkbox from '../../Checkbox'
import Dropdown from '../../Dropdown'
import ContentviewMessage from '../../ContentviewMessage'
import CronJobSelect from '@/Components/CronJobSelect'

const SftpWriteParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {
    const { setWarningResponse } = useResponse()
    const [connectorName, setConnectorName] = useState('')
    const [dataSources, setDataSources] = useState<any[]>([])
    const [dataMapInputValue, setDataMapInputValue] = useState('')
    const [dataMapOutputValue, setDataMapOutputValue] = useState('')
    const [scheduled, setScheduled] = useState(false)
    const [overwrite, setOverwrite] = useState(false)
    const [params, setParams] = useState({ ...defaultParams, connectorType: 'sftp', connectorAction: 'write' })

    useEffect(() => {
        if (_model.config.params.analytics && _model.config.params.analytics.length > 0) {
            const analytics = _model.config.params.analytics
            const readSources = analytics.filter(
                (analytic: any, index: number) =>
                    (analytic.params.connectorAction === 'read' || analytic.className === 'CustomAnalytic') &&
                    (stepIndex === -1 || index < stepIndex),
            )
            const sources = readSources.map(analytic =>
                analytic.label?.length > 0 ? analytic.label : analytic.className + ` ${analytic.params.connectorType ?? ''}`,
            )
            setDataSources(sources)
        }
    }, [_model])

    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                let _params = _model.config.params.analytics[stepIndex].params
                if(_params.schedule === '') {
                    _params.schedule = null
                }
                setConnectorName(_model.config.params.analytics[stepIndex].label)
                setParams({
                    ...params,
                    ..._params,
                })
                if (_params.schedule) {
                    setScheduled(true)
                }
            }
        }
    }, [stepIndex])

    if (!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const handleDatasourceChange = (event: any) => {
        const selectedIndex = Number(event.target.value)
        setParams({ ...params, dataSource: dataSources[selectedIndex] })
    }

    const onAddDataMapEntry = () => {
        if (dataMapInputValue !== '' && dataMapOutputValue !== '') {
            let newDataMap: DataMap
            newDataMap = { input: dataMapInputValue, output: dataMapOutputValue }
            setParams({ ...params, dataMap: [...params.dataMap, newDataMap] })
            setDataMapInputValue('')
            setDataMapOutputValue('')
        }
    }

    const onDataMapInput = (e: any) => {
        const { name, value } = e.target
        if (name === 'datamap_input') {
            setDataMapInputValue(value)
        } else if (name === 'datamap_output') {
            setDataMapOutputValue(value)
        }
    }

    const onRemoveDataMapEntry = (index: number) => {
        let _f = [...params.dataMap]
        _f.splice(index, 1)
        setParams({ ...params, dataMap: _f })
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onScheduledChange = (e: any) => {
        if (!e.target.checked) {
            setParams({ ...params, schedule: null })
        } else {
            setParams({ ...params, schedule: { timezone: '', cron: '* * * * *' } })
        }
        setScheduled(!scheduled)
    }

    const handleTimezoneOnChange = (value: string) => {
        if(!params.schedule) {
            setParams({ ...params, schedule: { timezone: value, cron: '' } })
        } else {
            setParams({ ...params, schedule: { ...params.schedule, timezone: value } })
        }
    }

    const handleCronOnChange = (value: string) => {
        if(!params.schedule) {
            setParams({ ...params, schedule: { timezone: '', cron: value } })
        } else {
            setParams({ ...params, schedule: { ...params.schedule, cron: value } })
        }
    }

    const onOverwriteChange = (e: any) => {
        setParams({ ...params, overwrite: e.target.checked })
        setOverwrite(e.target.checked)
    }


    const onSubmit = () => {
        let _params = { ...params }
        if (
            connectorName.length === 0 ||
            _params.dataSource.length === 0 ||
            _params.host.length === 0 ||
            _params.port.length === 0 ||
            _params.dbUser.length === 0 ||
            _params.password.length === 0 ||
            _params.path.length === 0 ||
            _params.fileName.length === 0 ||
            _params.dataMap.length === 0
        ) {
            setWarningResponse(
                'Required Fields',
                `Please complete all required fields: ${[
                    'Connector Name',
                    'Data Source',
                    'Name',
                    'URL',
                    'Port',
                    'Username',
                    'Password',
                    'Path',
                    'Filename',
                    'Schedule',
                    'Data Map',
                ].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
            )
            return
        }
        onSaveChanges(connectorName, _params)
    }

    if (dataSources.length === 0) {
        return <ContentviewMessage title='No Data Source created' message='Please create a read data source connector first' />
    }

    return (
        <div className='flex flex-col gap-4'>
            <div>
                <label className='px-2 text-[13px] text-ai'>Select data source*</label>
                <Dropdown
                    selectedIndex={dataSources.indexOf(params.dataSource)}
                    placeholder='Choose analytic class'
                    items={dataSources}
                    handleDropdownChange={handleDatasourceChange}
                    size='w-1/2'
                />
            </div>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name*</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                    disabled={stepIndex > -1}
                />
            </div>
            <hr className='border-ai-200' />
            <div className='grid grid-cols-6 gap-2'>
                <div className='col-span-full xl:col-span-4'>
                    <label className='px-2 text-[13px] text-ai'>URL*</label>
                    <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.host ?? ''} name='host' />
                </div>
                <div className='col-span-full xl:col-span-2'>
                    <label className='px-2 text-[13px] text-ai'>Port*</label>
                    <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.port ?? ''} name='port' />
                </div>
            </div>
            <div className='grid grid-cols-6 gap-2'>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Username*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.dbUser ?? ''}
                        name='dbUser'
                    />
                </div>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Password*</label>
                    <input
                        className='input w-full'
                        type={'password'}
                        onChange={e => {
                            handleFieldsOnChange(e)
                        }}
                        value={params.password ?? ''}
                        name='password'
                    />
                </div>
            </div>
            <div>
                <label className='px-2 text-[13px] text-ai'>Path*</label>
                <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.path ?? ''} name='path' />
            </div>
            <div className='grid grid-cols-6 gap-2'>
                <div className='col-span-full xl:col-span-6'>
                    <label className='px-2 text-[13px] text-ai'>Filename* (currently support .csv only)</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.fileName ?? ''}
                        name='fileName'
                    />
                </div>
            </div>
            <div className='flex flex-1 gap-1 px-1'>
                <Checkbox checked={params.overwrite} onChange={onOverwriteChange} />
                <label className='block px-2 text-sm text-ai'>Overwrite file with each run?</label>
            </div>
            <div className='py-2'>
                <hr className='border-ai-200' />
                <p className='mt-3 font-bold text-ai-700'>Data Map</p>
                <p className='mb-3 text-[13px] text-ai'>
                    Use <span className='rounded-md bg-ai-200 py-[2px] px-2 font-bold'>" . "</span> for nested keys
                </p>
                <div className='flex flex-col gap-3 rounded-sm border border-ai-200 bg-ai-100 p-2'>
                    <div className='flex items-center gap-2'>
                        <>
                            <div className='flex-1'>
                                <input
                                    name='datamap_input'
                                    className='input w-full'
                                    type='text'
                                    placeholder='Input value'
                                    value={dataMapInputValue}
                                    onChange={onDataMapInput}
                                />
                            </div>
                            <div className='flex-1'>
                                <input
                                    name='datamap_output'
                                    className='input w-full'
                                    type='text'
                                    placeholder='Output value'
                                    value={dataMapOutputValue}
                                    onChange={onDataMapInput}
                                />
                            </div>
                        </>
                        <div>
                            <button
                                className=' btn-secondary relative flex h-[40px] items-center border border-ai p-3 text-[14px] font-bold'
                                onClick={onAddDataMapEntry}
                            >
                                <AddCircleOutline sx={{ fontSize: '16px' }} />
                                <span className='px-1'>Add</span>
                            </button>
                        </div>
                    </div>

                    <div>
                        <table aria-label='datasources' className='w-full border border-ai-200'>
                            <thead className='border-b border-b-ai-200 bg-white text-left'>
                                <tr>
                                    <th className='w-[5%] px-4 py-2' scope='col'>
                                        <span className='text-[16px] text-ai-800'>#</span>
                                    </th>
                                    <th className='w-[45%]'>
                                        <span className='text-[16px] text-ai-800'>Input</span>
                                    </th>
                                    <th className='w-[45%]'>
                                        <span className='text-[16px] text-ai-800'>Output</span>
                                    </th>
                                    <th className='w-[5%]'></th>
                                </tr>
                            </thead>
                            <tbody className='text-left'>
                                {params.dataMap.length === 0 ? (
                                    <tr>
                                        <td colSpan={4}>
                                            <div className='w-full p-6 text-center font-[16px] text-ai-800'>No data map available yet.</div>
                                        </td>
                                    </tr>
                                ) : (
                                    params.dataMap.map((dataMap: DataMap, index: number) => (
                                        <tr key={index} className={`${index % 2 === 0 ? 'bg-ai-50' : 'bg-ai-100'}`}>
                                            <td className='p-4'>
                                                <span className='text-[16px] text-ai-800'>{index + 1}</span>
                                            </td>
                                            <td>
                                                <span className='text-ai-800'>{dataMap.input}</span>
                                            </td>
                                            <td>
                                                <span className='text-ai-800'>{dataMap.output}</span>
                                            </td>
                                            <td className='pr-3'>
                                                <button
                                                    className='btn-icon'
                                                    onClick={() => onRemoveDataMapEntry(index)}
                                                    aria-label={`Remove filter ${index + 1}`}
                                                    title='Delete Row'
                                                >
                                                    <DeleteOutlineIcon color='error' />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr className='border-ai-200' />
            {(_model.config.params.analytics?.length === 0 || stepIndex === 0) &&
                <>
                    <div className='flex flex-1 gap-2'>
                        <Checkbox checked={scheduled} onChange={onScheduledChange} />
                        <label className='block px-2 text-ai'>Schedule ETL?</label>
                    </div>
                    {scheduled && (
                        <CronJobSelect timezone={params.schedule?.timezone ?? ''} schedule={params.schedule?.cron ?? ''} onScheduleChange={handleCronOnChange} onTimezoneChange={handleTimezoneOnChange}/>
                    )}
                </>
            }
            <hr className='border-ai-200' />
            {/* footer */}
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button className='btn-secondary border-none bg-transparent text-[#FF0000]' onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default SftpWriteParams
