import axios from 'axios'
import { endpoints } from '../endpoints'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from 'react-oidc-context'

const useGetLogs = (jobId?: string) => {
    const auth = useAuth()

    return useQuery(
        ['etlLogs', jobId],
        async () => {
            const token = localStorage.getItem('token')
            const authtype = localStorage.getItem('authtype')
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                        'X-AIS-Tenant-Id': auth.user?.profile.tenant_id ?? '',
                    },
                }
                const response = await axios.get<{ data: any[] }>(`${endpoints.etlLogs}/${jobId}`, options)
                return response.data.data
            }
        },
        {
            enabled: !!jobId,
        },
    )
}

export default useGetLogs