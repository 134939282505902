import DashboardLayout from '@/Layouts/DashboardLayout'

import React from 'react'

type Props = {}

const Logs = (props: Props) => {
    return (
        <DashboardLayout title={'Logs'}>
            <p className='text-xl'>Hello Logs</p>
        </DashboardLayout>
    )
}

export default Logs
