import React from 'react'
import { RenderingProps } from './RenderingSteps'
import Editor, { Monaco } from '@monaco-editor/react'
import { getContainerIds } from '../../Utils/utils'
import Dropdown from '../Dropdown'
import ContentviewMessage from '../ContentviewMessage'
import VariableForm from './VariableForm'
import AddIcon from '@mui/icons-material/Add'

type VariableFormProps = {
    variable: {
        name: string
        type: string
        value: string
    }
    index: number
    onChangeVariable: (
        index: number,
        updatedVariable: {
            name: string
            type: string
            value: string
        },
    ) => void
    onRemoveVariable: (index: number) => void
}

const options = {
    readOnly: false,
    minimap: { enabled: false },
}

const CustomRenderingParams: React.FC<RenderingProps> = ({
    _model,
    stepIndex,
    onSaveChanges,
    onCancel,
}) => {
    const [containerIds, setContainerIds] = React.useState<string[]>([])
    const [tab, setTab] = React.useState(0)
    const [key, setKey] = React.useState(0)
    const [params, setParams] = React.useState({
        id: '',
        containerId: '',
        predictionNameKey: 'name',
        predictionValueKey: 'value',
        contentHtml:
            "<!--Rendering components should always be a single parent/element-->\n<div id='custom-air-container'>\n</div>",
        contentScript: '// Use this script for dynamic use of the html content',
        contentStyle: '',
        canToggle: false,
        variables: {} as any,
    })

    const [variables, setVariables] = React.useState<
        {
            name: string
            type: string
            value: string
        }[]
    >([])

    const convertArrayToObject = (
        variables: VariableFormProps[],
    ) => {
        const result: { [key: string]: { value: string; type: string } } = {}
        for (let i = 0; i < variables.length; i++) {
            const variable = variables[i]
            const { name, type, value } = variable.variable
            result[name] = {
                value,
                type,
            }
        }
        return result
    }

    // converts the variables into a object
    React.useEffect(() => {
        const tempConfigurableVariables = variables.map(
            variable => ({
                variable,
                index: 0,
                onChangeVariable: (index: number, updatedVariable: any) => {},
                onRemoveVariable: (index: number) => {},
            }),
        )

        const convertedVariables = convertArrayToObject(
            tempConfigurableVariables,
        )

        setParams(prevParams => ({
            ...prevParams,
            variables: convertedVariables,
        }))
    }, [variables])

    
    const addVariable = () => {
        const newVariable = { name: '', type: 'string', value: '' }
        setVariables([...variables, newVariable])
    }

    const removeVariable = (index: number) => {
        const updatedVariables = [...variables]
        updatedVariables.splice(index, 1)
        setVariables(updatedVariables)
    }

    const changeVariable = (
        index: number,
        updatedVariable: VariableFormProps['variable'],
    ) => {
        const updatedVariables = [...variables]
        updatedVariables[index] = updatedVariable
        setVariables(updatedVariables)
    }

    React.useEffect(() => {
        if (_model.config.params.renderingSteps) {
            setContainerIds(getContainerIds(_model))
            if (stepIndex > -1) {
                setParams({
                    ...params,
                    ..._model.config.params.renderingSteps[stepIndex].params,
                })
                setKey(key + 1)
            }
        }
    }, [stepIndex])

    if (!_model) {
        return null
    }

    const onContainerSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const index = Number(event.target.value)
        setParams({ ...params, containerId: containerIds[index] })
    }

    const handleFieldsOnChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.type === 'checkbox') {
            setParams({ ...params, [event.target.name]: event.target.checked })
        } else {
            setParams({ ...params, [event.target.name]: event.target.value })
        }
    }

    const onSubmit = () => {
        let _params: any = { ...params }
        onSaveChanges(_params)
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='font-bold text-ai-700'>Custom Rendering</p>
            <div>
                {containerIds.length > 0 && (
                    <>
                        <label
                            htmlFor='container-id'
                            className='block px-2 text-[13px] text-ai'
                        >
                            Container ID
                        </label>
                        <Dropdown
                            selectedIndex={containerIds.indexOf(
                                params.containerId,
                            )}
                            placeholder='Select container'
                            items={containerIds}
                            handleDropdownChange={onContainerSelect}
                        />
                    </>
                )}
                {containerIds.length === 0 && (
                    <ContentviewMessage
                        title='No Containers'
                        message='Create containers for specific injections'
                    />
                )}
            </div>
            <hr className='border-ai-200' />
            <p className='font-bold text-ai-700'>Identifiers</p>
            <div className='flex gap-2'>
                <div className='flex-1'>
                    <label
                        htmlFor='element-id'
                        className='block px-2 text-[13px] text-ai'
                    >
                        ID
                    </label>
                    <input
                        id='element-id'
                        className='input w-full'
                        name='id'
                        value={params.id}
                        placeholder='tag-id'
                        onChange={handleFieldsOnChange}
                    />
                </div>
            </div>
            <div className='flex gap-2'>
                <div className='flex-1'>
                    <label
                        htmlFor='prediction-name-key'
                        className='block px-2 text-[13px] text-ai'
                    >
                        Column Key
                    </label>
                    <input
                        className='input w-full'
                        id='prediction-name-key'
                        placeholder='name'
                        name='predictionNameKey'
                        value={params.predictionNameKey}
                        onChange={handleFieldsOnChange}
                    />
                </div>
                <div className='flex-1'>
                    <label
                        htmlFor='prediction-name-key'
                        className='block px-2 text-[13px] text-ai'
                    >
                        Value Key
                    </label>
                    <input
                        className='input w-full'
                        id='prediction-value-key'
                        placeholder='value'
                        name='predictionValueKey'
                        value={params.predictionValueKey}
                        onChange={handleFieldsOnChange}
                    />
                </div>
            </div>
            <hr className='border-ai-200' />

            {/* Adds the VariableForm component */}
            <div className='flex flex-col gap-2'>
                <p className='mb-4 font-bold text-ai-700'>
                    Configurable Variables
                </p>
                {variables.length > 0 && (
                    <div className='flex text-sm text-ai'>
                        <p className='flex-1'>Name</p>
                        <p className='flex-1'>Value</p>
                        <p className='relative -left-2 flex-1'>Type</p>
                    </div>
                )}
                {variables.map((variable, index) => (
                    <VariableForm
                        key={index}
                        variable={variable}
                        index={index}
                        onChangeVariable={changeVariable}
                        onRemoveVariable={removeVariable}
                    />
                ))}
                <button
                    className='mt-2 flex justify-center gap-2 rounded-md p-2 transition duration-300 ease-in-out hover:bg-ai-50'
                    onClick={addVariable}
                >
                    <AddIcon /> Add Variable
                </button>
            </div>

            <hr className='border-ai-200' />

            <div className='flex w-full'>
                <div className='flex-1 p-2'>
                    <button
                        onClick={() => setTab(0)}
                        className={`btn-secondary h-full w-full ${
                            tab === 0 && 'border-b-2 border-b-ai'
                        }`}
                    >
                        HTML
                    </button>
                </div>
                <div className='flex-1 p-2'>
                    <button
                        onClick={() => setTab(1)}
                        className={`btn-secondary h-full w-full ${
                            tab === 1 && 'border-b-2 border-b-ai'
                        }`}
                    >
                        JS
                    </button>
                </div>
                <div className='flex-1 p-2'>
                    <button
                        onClick={() => setTab(2)}
                        className={`btn-secondary h-full w-full ${
                            tab === 2 && 'border-b-2 border-b-ai'
                        }`}
                    >
                        CSS
                    </button>
                </div>
            </div>
            {tab === 0 && (
                <Editor
                    key={key}
                    options={options}
                    height='300px'
                    defaultLanguage='html'
                    defaultValue={params.contentHtml}
                    onChange={html => {
                        setParams({
                            ...params,
                            contentHtml:
                                html ?? "<div id='custom-air-container'></div>",
                        })
                    }}
                />
            )}
            {tab === 1 && (
                <Editor
                    key={key + 1}
                    options={options}
                    height='300px'
                    defaultLanguage='javascript'
                    defaultValue={
                        params.contentScript ||
                        '// Use this script for dynamic use of the html content'
                    }
                    onChange={js => {
                        setParams({
                            ...params,
                            contentScript:
                                js ??
                                '// Use this script for dynamic use of the html content',
                        })
                    }}
                />
            )}
            {tab === 2 && (
                <Editor
                    key={key + 2}
                    options={options}
                    height='300px'
                    defaultLanguage='css'
                    defaultValue={params.contentStyle}
                    onChange={css => {
                        setParams({
                            ...params,
                            contentStyle: css ?? '',
                        })
                    }}
                />
            )}
            <hr className='border-ai-200' />
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button
                    className='btn-secondary border-none bg-transparent text-[#FF0000]'
                    onClick={onCancel}
                >
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default CustomRenderingParams
