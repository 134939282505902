import './App.css'
import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { theme } from './Theme/theme'
import ProtectedRoute from './Layouts/ProtectedRoute'
import Models from './Views/Workspace'
import Registry from './Views/Registry'
import Users from './Views/Users/Users'
import Groups from './Views/Groups/Groups'
import { ModelInsights, ModelConfiguration } from './Views/Model'
import ModelEdit from './Views/Workspace/ModelEdit'
import { ROUTER_PATHS } from './Utils/constants'
import { Alert, AlertTitle, Box, Button, Typography, Snackbar, Slide } from '@mui/material'
import { useResponse } from './Store/Hooks'
import { FeedbackResponse } from './Store/Slices/responseSlice'
import ModelPermissions from './Views/Model/ModelPermissions'
import UserDetails from './Views/Users/UserDetails'
import GroupDetails from './Views/Groups/GroupDetails'
import Landing from './Views/Landing/Landing'
import ModelPredictionFeedback from './Views/Model/ModelPredictionFeedback'
import ModelGenerativeFeedback from './Views/Model/ModelGenerativeFeedback'
import Credentials from './Views/Machine-learning/Credentials'
import Login from './Views/Login'
import Tuning from './Views/Machine-learning/Tuning'
import Serving from './Views/Machine-learning/Serving'
import TuningCreate from './Views/Machine-learning/TuningCreate'
import ServingCreate from './Views/Machine-learning/ServingCreate'
import ModelJobs from './Views/Model/ModelJobs'
import Logs from './Views/Logs/Logs'
import ModelJobsLogs from './Views/Model/ModelJobsLogs'

function App() {
    const { response, removeResponse } = useResponse()
    const [openSnackbar, setOpenSnackbar] = React.useState(false)
    const [inMemoryResponse, setInMemoryResponse] = React.useState<FeedbackResponse | null>(null)

    React.useEffect(() => {
        if (response !== null) {
            setInMemoryResponse(response)
            setOpenSnackbar(true)
            removeResponse()
        }
    }, [response])

    const onSnackbarAction = () => {
        setOpenSnackbar(false)
        setInMemoryResponse(null)
    }

    return (
        // add "debug-screens" string to the div with className bellow to see the TailwindCSS screen sizes
        <div className='h-full'>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path={ROUTER_PATHS.model}>
                            <Route
                                path={'insights'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelInsights />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'predfeedback'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelPredictionFeedback />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'genfeedback'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelGenerativeFeedback />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'permissions'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelPermissions />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'configuration'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelConfiguration />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'jobs'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelJobs />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'jobs/:jobId'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelJobsLogs />
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route
                            path={ROUTER_PATHS.workspaceEdit}
                            element={
                                <ProtectedRoute permission={'update model'}>
                                    <ModelEdit />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTER_PATHS.create}
                            element={
                                <ProtectedRoute permission={'create model'}>
                                    <ModelEdit create />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTER_PATHS.workspace}
                            element={
                                <ProtectedRoute permission={'list model'}>
                                    <Models />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTER_PATHS.models}
                            element={
                                <ProtectedRoute permission={'list model'}>
                                    <Registry />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTER_PATHS.users}
                            element={
                                <ProtectedRoute permission={['List All Groups', 'List All Users']}>
                                    <Users />
                                </ProtectedRoute>
                            }
                        />
                        <Route path={ROUTER_PATHS.users}>
                            <Route
                                path={':id'}
                                element={
                                    <ProtectedRoute permission={['List All Groups', 'List All Users']}>
                                        <UserDetails />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'profile'}
                                element={
                                    <ProtectedRoute>
                                        <UserDetails />
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route
                            path={ROUTER_PATHS.groups}
                            element={
                                <ProtectedRoute permission={['List All Groups', 'List All Users']}>
                                    <Groups />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTER_PATHS.group}
                            element={
                                <ProtectedRoute permission={['List All Groups', 'List All Users']}>
                                    <GroupDetails />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTER_PATHS.logs}
                            element={
                                <ProtectedRoute permission={'read model'}>
                                    <ModelJobsLogs />
                                </ProtectedRoute>
                            }
                        />
                        <Route path={'logs'} element={<Logs />} />
                        <Route path={ROUTER_PATHS.landing} element={<Landing />} />
                        <Route path={ROUTER_PATHS.login} element={<Login />} />
                        {/* <Route path={ROUTER_PATHS.credentials} element={
              <ProtectedRoute >
                <Credentials />
              </ProtectedRoute>
            } />
            <Route path={ROUTER_PATHS.tuning} element={
              <ProtectedRoute >
                <Tuning />
              </ProtectedRoute>
            } />
            <Route path={ROUTER_PATHS.tuningCreate} element={
              <ProtectedRoute >
                <TuningCreate />
              </ProtectedRoute>
            } />
            <Route path={ROUTER_PATHS.serving} element={
              <ProtectedRoute >
                <Serving />
              </ProtectedRoute>
            } />
            <Route path={ROUTER_PATHS.servingCreate} element={
              <ProtectedRoute >
                <ServingCreate />
              </ProtectedRoute>
            } /> */}
                    </Routes>
                </BrowserRouter>
                <Snackbar TransitionComponent={Slide} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar}>
                    <Alert
                        severity={inMemoryResponse?.severity ?? 'info'}
                        sx={{
                            border: '1px solid lightgray',
                        }}
                    >
                        <AlertTitle>
                            <Typography variant='h6'>{inMemoryResponse?.title}</Typography>
                        </AlertTitle>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Box flex={'1 0'}>
                                <Typography variant='subtitle2'>{inMemoryResponse?.message}</Typography>
                            </Box>
                            <Box flex={'0 0'}>
                                <Button variant='text' onClick={onSnackbarAction}>
                                    Ok
                                </Button>
                            </Box>
                        </Box>
                    </Alert>
                </Snackbar>
            </ThemeProvider>
        </div>
    )
}

export default App
