import React, { useEffect, useState } from 'react'
import { AnalyticsProps } from '../AnalyticsSteps'
import { useResponse } from '../../../Store/Hooks'
import { ConnectorQuery, defaultParams } from './ReverseMLUtils'
import 'react-js-cron/dist/styles.css'
import Checkbox from '../../Checkbox'
import CronJobSelect from '@/Components/CronJobSelect'

const RedshiftReadParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {
    const { setWarningResponse } = useResponse()
    const [connectorName, setConnectorName] = useState('')
    const [scheduled, setScheduled] = useState(false)
    const [params, setParams] = useState({ ...defaultParams, connectorType: 'redshift', connectorAction: 'read', authenticationType: 'UPCredentials' })

    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                const _params = _model.config.params.analytics[stepIndex].params
                setConnectorName(_model.config.params.analytics[stepIndex].label)
                setParams({
                    ...params,
                    ..._params,
                })
                if (_params.schedule) {
                    setScheduled(true)
                }
            }
        }
    }, [stepIndex])

    if (!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onScheduledChange = (e: any) => {
        if (!e.target.checked) {
            setParams({ ...params, schedule: null })
        } else {
            setParams({ ...params, schedule: { timezone: '', cron: '* * * * *' } })
        }
        setScheduled(!scheduled)
    }

    const handleTimezoneOnChange = (value: string) => {
        if(!params.schedule) {
            setParams({ ...params, schedule: { timezone: value, cron: '' } })
        } else {
            setParams({ ...params, schedule: { ...params.schedule, timezone: value } })
        }
    }

    const handleCronOnChange = (value: string) => {
        if(!params.schedule) {
            setParams({ ...params, schedule: { timezone: '', cron: value } })
        } else {
            setParams({ ...params, schedule: { ...params.schedule, cron: value } })
        }
    }

    const onSubmit = () => {
        let _params = { ...params }
        if (_params.connectorType === 'redshift' && _params.authenticationType === 'arn' &&
            (
                connectorName.length === 0 ||
                _params.arn.length === 0 ||
                _params.dbName.length === 0 ||
                _params.dbUser.length === 0 ||
                _params.clusterName.length === 0 ||
                _params.query.length === 0)) {
                setWarningResponse(
                    'Required Fields',
                    `Please complete all required fields: ${[
                        'ARN',
                        'DB Name',
                        'DB User',
                        'Cluster',
                        'Query'
                    ].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
                )
                return
        } else if (_params.connectorType === 'redshift' && _params.authenticationType === 'UPCredentials' &&
            (
                connectorName.length === 0 ||
                _params.dbName.length === 0 ||
                _params.dbUser.length === 0 ||
                _params.host.length === 0 ||
                _params.port.length === 0 ||
                _params.password.length === 0 ||
                _params.query.length === 0)) {
                setWarningResponse(
                    'Required Fields',
                    `Please complete all required fields: ${[
                        'Connector Name',
                        'DB Name',
                        'DB User',
                        'Host',
                        'Port',
                        'Password',
                        'Query',
                    ].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
                )
                return
        }
        onSaveChanges(connectorName, _params)
    }

    return (
        <div className='flex flex-col gap-4'>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name*</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                    disabled={stepIndex > -1}
                />
            </div>
            <hr className='border-ai-200' />
            <div>
                <p className='text-[13px] text-ai'>Authentication Type*</p>
                <div className='flex gap-5'>
                    <div className='relative flex items-center'>
                        <input
                            className='radio-parent peer'
                            name='authenticationType'
                            id='UPCredentials'
                            type='radio'
                            value='UPCredentials'
                            checked={params.authenticationType === 'UPCredentials'}
                            onChange={handleFieldsOnChange}
                        />
                        <div className='radio' />
                        <label htmlFor='UPCredentials' className='cursor-pointer truncate pl-3'>
                            User Credentials
                        </label>
                    </div>

                    <div className='relative flex items-center'>
                        <input
                            className='radio-parent peer'
                            name='authenticationType'
                            id='connectedApp'
                            type='radio'
                            value='arn'
                            checked={params.authenticationType === 'arn'}
                            onChange={handleFieldsOnChange}
                        />
                        <div className='radio' />
                        <label htmlFor='connectedApp' className='cursor-pointer truncate pl-3'>
                            ARN
                        </label>
                    </div>
                </div>
            </div>
            <div className='flex-1'>
                <label className='flex-1 px-2 text-[13px] text-ai'>DB Name*</label>
                <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.dbName ?? ''} name='dbName' />
            </div>
            {params.authenticationType === 'arn' && (
                <>
                    <div className='col-span-full xl:col-span-3'>
                        <label className='flex-1 px-2 text-[13px] text-ai'>Username*</label>
                        <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.dbUser ?? ''} name='dbUser' />
                    </div>
                    <div className='flex gap-2'>
                        <div>
                            <label className='px-2 text-[13px] text-ai'>ARN</label>
                            <input
                                className='input w-full'
                                type={'text'}
                                onChange={handleFieldsOnChange}
                                value={params.arn ?? ''}
                                name='arn'
                            />
                        </div>
                        <div className='flex-1'>
                            <label className='flex-1 px-2 text-[13px] text-ai'>Cluster Name</label>
                            <input
                                className='input w-full'
                                type={'text'}
                                onChange={handleFieldsOnChange}
                                value={params.clusterName ?? ''}
                                name='clusterName'
                            />
                        </div>
                    </div>
                </>
            )}
            {params.authenticationType === 'UPCredentials' && (
                <>
                    <div className='grid grid-cols-6 gap-2'>
                        <div className='col-span-full xl:col-span-3'>
                            <label className='flex-1 px-2 text-[13px] text-ai'>Username*</label>
                            <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.dbUser ?? ''} name='dbUser' />
                        </div>
                        <div className='col-span-full xl:col-span-3'>
                            <label className='px-2 text-[13px] text-ai'>Password*</label>
                            <input
                                className='input w-full'
                                type={'password'}
                                onChange={e => {
                                    handleFieldsOnChange(e)
                                }}
                                value={params.password ?? ''}
                                name='password'
                            />
                        </div>
                    </div>
                    <div className='flex gap-2'>
                        <div className='flex-1 '>
                            <label className='px-2 text-[13px] text-ai'>Host*</label>
                            <input
                                className='input w-full'
                                type={'text'}
                                onChange={handleFieldsOnChange}
                                value={params.host ?? ''}
                                name='host'
                            />
                        </div>
                        <div className='flex-1 '>
                            <label className='flex-1 px-2 text-[13px] text-ai'>Port*</label>
                            <input
                                className='input w-full'
                                type={'text'}
                                onChange={handleFieldsOnChange}
                                value={params.port ?? ''}
                                name='port'
                            />
                        </div>
                    </div>
                </>
            )}
            <hr className='border-ai-200' />
            <ConnectorQuery _model={_model} connectorName={connectorName} params={params} queryLanguage='pgsql' previewValidations={ params.authenticationType === 'arn' ? ['arn', 'dbName', 'dbUser', 'clusterName', 'query'] : ['dbName', 'dbUser', 'host', 'port', 'password', 'query']} onQueryChange={(query) => setParams({...params, query: query ?? ''})} />
            <hr className='border-ai-200' />
            {(_model.config.params.analytics?.length === 0 || stepIndex === 0) &&
                <>
                    <div className='flex flex-1 gap-2'>
                        <Checkbox checked={scheduled} onChange={onScheduledChange} />
                        <label className='block px-2 text-ai'>Schedule ETL?</label>
                    </div>
                    {scheduled && (
                        <CronJobSelect timezone={params.schedule?.timezone ?? ''} schedule={params.schedule?.cron ?? ''} onScheduleChange={handleCronOnChange} onTimezoneChange={handleTimezoneOnChange}/>
                    )}
                </>
            }
            <hr className='border-ai-200' />
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button className='btn-secondary border-none bg-transparent text-[#FF0000]' onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default RedshiftReadParams
