import React from "react"
import { HarvesterProps } from "./HarvesterSteps"
import Checkbox from "../Checkbox"

const ChatbotHarvesterParams: React.FC<HarvesterProps> = ({ _model, onSaveChanges, onRemoveClass }) => {

    const [params, setParams] = React.useState({
        title: 'Chatbot',
        harvestHistory: false
    })
  
    React.useEffect(() => {
        if (_model && _model.config?.params?.harvestingSteps && _model.config?.params?.harvestingSteps?.length > 0 && _model.config?.params?.harvestingSteps[0].className === 'ChatbotHarvester' ) {
            setParams({ ...params, ..._model.config?.params?.harvestingSteps[0].params })
        }
    }, [])

    if(!_model) {
        return null
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.type === 'checkbox') {
            setParams({ ...params, harvestHistory: event.target.checked })
        } else {
            setParams({ ...params, [event.target.name]: event.target.value })
        }
    }

    const onSubmit = () => {
        onSaveChanges(params)
    }

    return (
        <div className="flex flex-col gap-4">
            <p className="text-ai-800  text-[16px]">Creates a Chatbot-like widget for users to interact with.</p>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleFieldsOnChange}
                    value={params.title}
                    placeholder='Give the chatbot a name'
                    name='title'
                />
            </div>
            <div>
                <div className='flex flex-1 gap-2'>
                    <Checkbox checked={params.harvestHistory} onChange={handleFieldsOnChange} />
                    <label className='block px-2 text-ai'>Harvest full history?</label>
                </div>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]" disabled={!_model.config.params.harvestingSteps || _model.config.params.harvestingSteps.length === 0} onClick={onRemoveClass}>Remove Class</button>
            </div>
        </div>
    )
}

export default ChatbotHarvesterParams