import * as React from 'react'
import { Check, ChevronsUpDown } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/Components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/Components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/Components/ui/popover'
import { TIMEZONES_ARRAY } from '@/Utils/constants'
import Cron from 'react-js-cron'

const timezones = TIMEZONES_ARRAY.map(zone => {
    return {
        value: zone,
        label: zone,
    }
})

interface iCronJobSelect {
    schedule: string
    timezone: string
    onTimezoneChange: (timezone: string) => void
    onScheduleChange: (schedule: string) => void
}

const CronJobSelect: React.FC<iCronJobSelect> = ({ schedule, timezone, onTimezoneChange, onScheduleChange }) => {

    const [open, setOpen] = React.useState(false)
    const [value, setValue] = React.useState('')
    const [date, setDate] = React.useState<string | null>(null)

    React.useEffect(() => {
        let dateInterval: NodeJS.Timer | null = null
        if(timezone && timezone.length > 0) {
            setValue(timezone)
            dateInterval = setInterval(() => {
                setDate(new Date().toLocaleString('en-US', { timeZone: timezone }))
            }, 1000);
        }
        return () => {
            if(dateInterval) {
                clearInterval(dateInterval)
            }
        }
    }, [timezone])

    return (
        <>
            <div className='flex flex-col gap-2 p-4 bg-ai-50 rounded-md'>
                <div>
                    <label htmlFor="badge-color" className="block px-2 text-[13px] text-ai">Select Timezone</label>
                    <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>
                            <Button variant='outline' role='combobox' aria-expanded={open} className='w-1/2 justify-between'>
                                {value ? timezones.find(zone => zone.value.toLowerCase() === value.toLowerCase())?.label : 'Select timezone...'}
                                <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className='h-[350px] p-0'>
                            <Command>
                                <CommandInput placeholder='Search timezone...' />
                                <CommandEmpty>No timezone found.</CommandEmpty>
                                <CommandGroup className='overflow-auto'>
                                    {timezones.map(zone => (
                                        <CommandItem
                                            key={zone.value}
                                            value={zone.value}
                                            onSelect={currentValue => {
                                                onTimezoneChange(currentValue)
                                                setValue(currentValue === value ? '' : currentValue)
                                                setOpen(false)
                                            }}
                                        >
                                            <Check className={cn('mr-2 h-4 w-4', value === zone.value ? 'opacity-100' : 'opacity-0')} />
                                            {zone.label}
                                        </CommandItem>
                                    ))}
                                </CommandGroup>
                            </Command>
                        </PopoverContent>
                    </Popover>
                    <label className="block p-1 text-[13px] text-ai-800">Time at <strong>{value}</strong>: {date}</label>
                </div>
                <hr className='border-ai-200' />
                <Cron value={schedule} setValue={onScheduleChange}/>
            </div>
        </>
    )
}

export default CronJobSelect
